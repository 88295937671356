import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from "Common/TableContainer";
import { getByStaffDetail, getStaffDocumentDetail } from 'helpers/saibackend_helper';
import { Offcanvas, Button, Row, Col, Card, Table } from "react-bootstrap";
import profile from 'assets/images/auth/profile.png'
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const CheckpointTable = ({ data, currentPage, totalPages, onPageChange, handleSortChange }:
    {
        data: any,
        currentPage: number;
        totalPages: number;
        onPageChange: (page: number) => void;
        handleSortChange: (column: string, direction: string) => void;
    }) => {

    const columns = useMemo(
        () => [
            {
                header: "Sr No.",
                disableFilters: true,
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "QR ID",
                disableFilters: true,
                accessorKey: "qr_id",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Staff",
                disableFilters: true,
                accessorKey: "staff",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Client",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "client",
            },
            {
                header: "Location",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "location",
            },
            {
                header: "Address",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "address",
            },
            {
                header: "Created Date",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "created_at_date",
            },
            {
                header: "Action",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const { user_latitude, user_longitude } = cellProps.row.original;

                    const openGoogleMaps = () => {
                        const googleMapsUrl = `https://www.google.com/maps?q=${user_latitude},${user_longitude}`;
                        window.open(googleMapsUrl, '_blank');
                    };

                    return (
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            <div className="edit">
                                <Button type="button" variant="primary  " className="btn w-sm" onClick={openGoogleMaps}>
                                <i
                                    className="bx bx-map-pin"
                                ></i> View On Map</Button>                                 
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            {data.length > 0 ?
                <TableContainer
                    isPagination={true}
                    columns={(columns || [])}
                    data={data}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
                :
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">no activity logs available</h5>

                    </div>
                </div>
            }
        </React.Fragment >
    );
}

export default CheckpointTable;