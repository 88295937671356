import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Dropdown,
  ButtonGroup,
  Tab,
  Nav,
  Spinner,
  Table,
  Modal,
  ListGroup,
  Accordion
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import ScheduleWeekTableNew from "./ScheduleTable/ScheduleWeekTableNew";
import SimpleBar from "simplebar-react";
import Select from "react-select";
import { getScheduleClientList, getAllLocationScheduleList, createScheduleShift, pasteScheduleShift, getByStaffDetail, archiveShiftDetail, exportPatrolLocationInfoDetail, getShiftDetail, ShiftHistoryList, getStaffDocumentDetail, getScheduleStaffList, getSchedulePublishDetailWithLocation, publishShiftsWithLocation, ExportShift, submitShiftTemplate, loadShiftTemplate, saveShiftTemplate, ShiftTemplateArchiveById, updateScheduleShift, getShiftSummary, getLocationDropdownList, getSchedulePublishShiftList, finishedBulkShift } from "helpers/saibackend_helper";
import moment from 'moment';
import * as Yup from "yup";
import { useFormik } from "formik";
import Cleave from 'cleave.js/react';
import { toast } from 'react-toastify';
import noDataImg from "assets/images/no-data.png"
import { Link } from 'react-router-dom';
moment.updateLocale('en', { week: { dow: 1 } });

interface DropdownData {
  value: number;
  label: string;
}
interface PublishDateItem {
  date: string;
  dayName: string;
}
interface PublishStaffItem {
  staff_id: string;
  staff_name: string;
}

interface PublishLocationItem {
  location_id: string;
  name: string;
}

const ScheduleNew = () => {
  document.title = "Dashboard | The Guard Duty";

  const [paste_alert_standard, setpaster_alert_standard] = useState<boolean>(false);
  function paste_standard() {
    setpaster_alert_standard(!paste_alert_standard);
  }

  const [shiftFilters, setShiftFilters] = useState<boolean>(false);

  const FilterOpen = (() => {
    if (pasteMode) {
      paste_standard()
    } else {
      setShiftFilters(!shiftFilters)
    }
  })

  const handleTabChange = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };

  const getInitials = (name: string): string => {
    const names = name.split(" ");
    const firstNameInitial = names[0].charAt(0).toUpperCase();
    const lastNameInitial =
      names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : "";
    return `${firstNameInitial}${lastNameInitial}`;
  };

  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [isClear, setIsClear] = useState(false);

  const allStatusOptions = ['Un-assigned Shifts', 'Unpublished Shifts', 'Publish Shifts', 'Finish Shifts', 'Archive Shifts'];

  const handleAllStatusChange = () => {
    if (selectedStatus.length === allStatusOptions.length) {
      setSelectedStatus([]);
    } else {
      setSelectedStatus(allStatusOptions.slice());
    }
  };

  const handleStatusChange = (status: string) => {
    const updatedStatus = [...selectedStatus];

    if (updatedStatus.includes(status)) {
      updatedStatus.splice(updatedStatus.indexOf(status), 1);
    } else {
      updatedStatus.push(status);
    }

    setSelectedStatus(updatedStatus);
  };

  const handleClearAllStatus = () => {
    setSelectedStatus([]);
  };

  const ClearAllFilter = async () => {
    setSelectedFilterLocation([]);
    setIsClear(!isClear);
    setShiftFilters(false);
  };

  /*Location Wise Scheduling STart */
  const [clientList, setClientList] = useState<DropdownData[]>([]);
  const [clientSearch, setClientSearch] = useState<string>("");
  const [selectedClient, setSelectedClient] = useState<DropdownData | null>(null);
  const [eventList, setEventList] = useState<any[]>([]);
  const [eventDates, setEventDates] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchClientList = async (searchValue: string) => {
    try {
      const response = await getScheduleClientList(searchValue);
      const clients = response.data.map((client: any) => ({
        value: client.value,
        label: client.label,
      }));
      setClientList(clients);

      const storedClient = localStorage.getItem('selectedClient');

      if (clients.length > 0) {
        if (storedClient) {
          setSelectedClient(JSON.parse(storedClient));
        } else {
          setSelectedClient(clients[0]);
          localStorage.setItem('selectedClient', JSON.stringify(clients[0]));
        }
      } else {
        setSelectedClient(null);
      }
    } catch (error) {
      console.log(error);
    }
    setSelectedFilterLocation([]);
  };

  const fetchEventsFromAPI = async () => {
    try {
      if (selectedClient) {
        const client: any = selectedClient?.value;
        const formattedStart = moment(startDate).format("YYYY-MM-DD");
        const formattedEnd = moment(endDate).format("YYYY-MM-DD");
        const locationIds = selectedFilterLocation.map((location: any) => location.value);

        const response = await getAllLocationScheduleList(client, formattedStart, formattedEnd, locationIds);
        setEventList(response.data.data_list);
        setEventDates(response.data.dates);
        setTotalLocation(response.data.data_list.length);
        const summaryResponse = await getShiftSummary(client, formattedStart, formattedEnd);
        setTotalShifts(summaryResponse.data?.total_shift);
        setTotalPublishShifts(summaryResponse.data?.total_published_shift);
        setTotalFinishShifts(summaryResponse.data?.total_finished_shift);
        setTotalUnpublishShifts(summaryResponse.data?.total_unpublished_shift);
        setTotalUnassignShifts(summaryResponse.data?.total_unassigned_shift);
        setTotalArchivedShifts(summaryResponse.data?.total_archived_shift);

        const locationResp = await getLocationDropdownList(client, locationSearch);
        setFilterLocationList(locationResp?.data?.data_list);

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*Location Wise Scheduling STart */

  /* NEW */
  const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

  const doc_url = process.env.REACT_APP_IMAGE_URL
  const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

  const [staffList, setStaffList] = useState<DropdownData[]>([]);
  const [isShiftCanvasOpen, setIsShiftCanvasOpen] = useState<boolean>(false);
  const [selectedStaffName, setSelectedStaffName] = useState<string>("");
  const [editId, setEditId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<string>("home1");
  const [isFinishShift, setIsFinishShift] = useState<boolean>(false);
  const [shiftData, setShiftData] = useState<any>([]);
  const [shiftHours, setShiftHours] = useState<number>(9);
  const [selectedLocation, setSelectedLocation] = useState<DropdownData | null>(null);
  const [isAccept, setIsAccept] = useState<any>("")
  const [shiftClockIn, setShiftClockIn] = useState<any>("")
  const [shiftClockOut, setShiftClockOut] = useState<any>("");
  const [Created_by, setCreatedBy] = useState<string>("")
  const [totalShifts, setTotalShifts] = useState<number>(0);
  const [totalLocation, setTotalLocation] = useState<number>(0);
  const [totalPublishShifts, setTotalPublishShifts] = useState<number>(0);
  const [totalFinishShifts, setTotalFinishShifts] = useState<number>(0);
  const [totalUnpublishShifts, setTotalUnpublishShifts] = useState<number>(0);
  const [totalUnassignShifts, setTotalUnassignShifts] = useState<number>(0);
  const [totalArchivedShifts, setTotalArchivedShifts] = useState<number>(0);
  const [archiveModal, setArchiveModal] = useState<boolean>(false);
  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [Is_petrol_site, setIs_petrol_site] = useState<number>(0);
  const [ShiftHistoryDetails, setShiftHistoryDetails] = useState<any[]>([]);
  const [locationSearch, setLocationSearch] = useState<string>("");
  const [staffSearch, setStaffSearch] = useState<string>("");
  const [filterLocationList, setFilterLocationList] = useState<DropdownData[]>([]);
  const [selectedFilterLocation, setSelectedFilterLocation] = useState<number[]>([]);

  /*------------------------------Formik Start--------------------------------*/
  const [initialValues, setInitialValues] = useState({
    date: "",
    staff_id: 0,
    start_time: "09:00",
    end_time: "18:00",
    clock_in_time: "",
    clock_out_time: "",
    total_unpaid_break: "",
    clock_in_latitude: "",
    clock_in_longitude: "",
    clock_out_latitude : "",
    clock_out_longitude : "",
    total_paid_break: "",
    shift_notes: "",
    admin_note: "",
    is_finished: 0,
    client_id: selectedClient?.value,
    location_id: selectedLocation?.value,
    shift_id: 0,
    is_published: 0
  });

  const shiftValidationSchema = Yup.object().shape({
    date: Yup.string().required("Please select a date"),
    start_time: Yup.string()
      .required("Start time is required"),
    end_time: Yup.string()
      .required("Finish time is required"),
    total_unpaid_break: Yup.number()
      .min(0)
      .integer("Unpaid break must be an integer").nullable(),
    total_paid_break: Yup.number()
      .min(0)
      .integer("Paid break must be an integer").nullable(),
    shift_notes: Yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: shiftValidationSchema,
    onSubmit: async (values) => {
      const formattedStartTime = moment(values.start_time, "HH:mm").format("HH:mm");
      const formattedEndTime = moment(values.end_time, "HH:mm").format("HH:mm");
      const formattedClockinTime = values.clock_in_time ? moment(values.clock_in_time, "HH:mm").format("HH:mm") : "";
      const formattedClockoutTime = values.clock_out_time ? moment(values.clock_out_time, "HH:mm").format("HH:mm") : "";

      const postdata = {
        ...values,
        location_id: selectedLocation?.value,
        shift_id: editId,
        staff_id: values.staff_id ?? 0,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        clock_in_time: formattedClockinTime,
        clock_out_time: formattedClockoutTime,
        is_accept: editId ? isAccept : undefined
      };
      const response = await createScheduleShift(postdata);
      if (response.status === 1) {
        toast.success(response.message);
        fetchEventsFromAPI();
        formik.resetForm();
        setIsShiftCanvasOpen(false);
      } else {
        toast.error(response.message);
        return;
      }
    }
  });

  const calculateShiftHours = (startTime: string, endTime: string, unpaidBreak: number): number => {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    if (end.isBefore(start)) {
      end.add(1, 'day');
    }

    const duration = moment.duration(end.diff(start));
    const totalHours = parseFloat(duration.asHours().toFixed(2));

    // Deduct unpaid break time (convert minutes to hours)
    const unpaidBreakHours = unpaidBreak / 60;
    const netHours = totalHours - unpaidBreakHours;

    return parseFloat(netHours.toFixed(2));
  };

  const handleTimeChange = (time: string, field: string) => {
    formik.setFieldValue(field, time);
    if (field === "start_time" || field === "end_time") {
      const formattedTime = moment(time, "HH:mm").format("HH:mm");
      const startTime = (field === "start_time") ? formattedTime : formik.values.start_time;
      const endTime = (field === "end_time") ? formattedTime : formik.values.end_time;

      if (startTime && endTime) {
        const shiftHours = calculateShiftHours(startTime, endTime, Number(formik.values.total_unpaid_break));
        setShiftHours(shiftHours);
      }
    }
  };

  const handleEventDrop = async (event: any, newDate: any, locationData: any) => {
    if (event.class_name === "finished" || event.class_name === "archived" || event.status != 1 || event.status_name === "CLOCKED IN") {
      // arg.revert();
    } else {
      if(moment(event.date).format("DD-MM-YYYY") == newDate?.value && locationData.location_id == event.location_id){
        return;
      }
      try {
        const postdata = {
          id: event.id,
          date: newDate?.value,
          location_id: locationData.location_id
        };
        const response = await updateScheduleShift(postdata);
        if (response.status === 1) {
          toast.success(response.message);
          fetchEventsFromAPI();
        } else {
          toast.error(response.message);
          // arg.revert();
          return;
        }
      } catch (error) {
        console.error("Error updating event:", error);
      }
    }
  };
  /*------------------------------Formik End----------------------------------*/

  /*Shift Template Start */
  const templateValidationSchema = Yup.object().shape({
    template_name: Yup.string().required("Please enter a template name"),
  });

  const [template_id, setTemplateId] = useState<number>(0);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [openLoadTemplateModal, setOpenLoadTemplateModal] = useState<boolean>(false);
  const [savingTemplateShifts, setSavingTemplateShifts] = useState<boolean>(false);
  const [submitTemplateResponse, setSubmitTemplateResponse] = useState<any>(null);
  const [templateData, setTemplateData] = useState<any>([]);
  const templateFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      'template_name': '',
      'description': '',
      'is_shift_notes': 0,
      'is_admin_notes': 0,
      'is_remove_staff': 0
    },
    validationSchema: templateValidationSchema,
    onSubmit: async (values) => {
      try {
        const client: any = selectedClient?.value;

        const formattedStart = moment(startDate).format("YYYY-MM-DD");
        const formattedEnd = moment(endDate).format("YYYY-MM-DD");

        const payload = {
          ...values,
          client_id: client,
          start_date: formattedStart,
          end_date: formattedEnd,
        }
        const response = await saveShiftTemplate(payload);
        if (response.status === 1) {
          toast.success(response.message);
          setOpenTemplateModal(false);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  });

  const loadShiftTemplateFunc = async () => {
    try {
      const client: any = selectedClient?.value;
      const payload = {
        client_id: client
      }
      const response = await loadShiftTemplate(payload);
      if (response.status === 1) {
        setTemplateData(response.data.data_list)
        if (response.data.data_list.length > 0 && response.data.data_list[0].id) {
          setTemplateId(response.data.data_list[0].id);
        }
        setOpenLoadTemplateModal(true);
        setSubmitTemplateResponse(null);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  }

  const submitShiftTemplateFunc = async () => {
    try {
      setSavingTemplateShifts(true);
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      const payload = {
        template_id,
        start_date: formattedStart,
        end_date: formattedEnd,
      }
      const response = await submitShiftTemplate(payload);
      if (response.status === 1) {
        toast.success(response.message);
        fetchEventsFromAPI();
        setSubmitTemplateResponse(response.data);
        // setOpenLoadTemplateModal(false);
        setSavingTemplateShifts(false);
      } else {
        setSavingTemplateShifts(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      setSavingTemplateShifts(false);
      toast.error("Something went wrong");
    }
  }

  const [archiveTemplateId, setArchiveTemplateId] = useState<number | undefined>(undefined);

  const handleArchiveShiftTemplate = async (id: number): Promise<void> => {
    setArchiveTemplateId(id);
    setmodal_center(true);
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }


  const [archiveLoader, setArchiveLoader] = useState(false)

  const archiveShiftTemplate = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await ShiftTemplateArchiveById(id);
      toast.success(response.message);
      setArchiveLoader(false)
      setmodal_center(false)
      loadShiftTemplateFunc()
    } catch (error) {
      toast.error("Error Archive Please try again later.");
    }
  };
  /*Shift Template End */

  const createShiftShow = (date: any, location: DropdownData) => {
    formik.resetForm();
    setSelectedStaffName("");
    setEditId(null);
    setActiveTab('home1');
    setIsFinishShift(false);
    setShiftHours(9);

    // Check if pasteMode is true or false
    if (pasteMode) {
      // Call paste_standard logic if pasteMode is true
      paste_standard();
    } else {
      // Standard logic when pasteMode is false
      formik.setFieldValue("date", date);
      formik.setFieldValue("client_id", selectedClient?.value);
      formik.setFieldValue("location_id", selectedLocation?.value);
      setIsShiftCanvasOpen(true);
      setShiftClockIn('');
      setShiftClockOut('');
      setSelectedLocation(location);
    }
  };
  ;

  const handleEventClick = async (event: any) => {
    try {
      if(pasteMode){
        return;
      }
      // Check if it's a pasted event
      if (event.copy_from) {
        // Handle pasted shift locally
        console.log("Pasted event details:", event);

        if (event.location_id) {
          console.log("Fetching staff list for location:", event.location_id);
          await fetchStaffList(event.location_id, "");  // Pass the location_id and searchValue (empty string in this case)
        } else {
          console.log("Location ID is missing in the event details.");
        }

        // Assuming that pasted events share the same structure, update the state with the pasted event details
        // setSelectedStaffName(event.staff_name);
        setCreatedBy(event.created_by);
        setEditId(event.id);
        setShiftClockIn(event.start);  // Adjust if your pasted event has a different clock-in structure
        setShiftClockOut(event.end);   // Adjust if your pasted event has a different clock-out structure
        setIs_petrol_site(event.is_petrol_site);
        setSelectedLocation({ "label": event.location_name, "value": event.location_id });

        const formattedStartTime = moment(event.start).format("HH:mm");
        const formattedEndTime = moment(event.end).format("HH:mm");
        const formattedDate = moment(event.date).format("DD-MM-YYYY");

        // Set the form values for pasted event
        formik.setValues({
          ...formik.values,
          date: formattedDate,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          staff_id: event.staff_id,
          location_id: event.location_id,
          shift_notes: event.shift_notes,
          // Add other fields as necessary
        });

        setIsShiftCanvasOpen(true);
        setActiveTab('home1');
        setIsFinishShift(event.is_finished || event.is_archive);
        return;  // Exit early to avoid the API call
      }

      // If not a pasted event, proceed with API call as usual
      const response = await getShiftDetail(event.id);
      const history = await ShiftHistoryList(event.id);
      const historyData = history?.data ? history.data : null;
      setShiftHistoryDetails(historyData);

      if (response.status === 1) {
        const event_data = response.data;
        setIs_petrol_site(event_data?.is_petrol_site);
        setSelectedStaffName(event.staff_name);
        setCreatedBy(event_data?.created_by);
        setEditId(event.id);
        editMaster(event_data.staff_id);
        setShiftClockIn(event_data.clock_in_time);
        setShiftClockOut(event_data.clock_out_time);
        fetchStaffDocumentDetails(event_data.staff_id);
        setIsAccept(event_data.is_accept);
        setActiveTab('home1');
        setIsShiftCanvasOpen(true);
        setSelectedLocation({ "label": event.location_name, "value": event.location_id });

        const formattedStartTime = moment(event_data.start_time).format("HH:mm");
        const formattedEndTime = moment(event_data.end_time).format("HH:mm");
        const formattedDate = moment(event_data.date).format("DD-MM-YYYY");
        const formattedClockinTime = event_data.clock_in_time
          ? moment(event_data.clock_in_time).format("HH:mm")
          : "";
        const formattedClockoutTime = event_data.clock_out_time
          ? moment(event_data.clock_out_time).format("HH:mm")
          : "";

        const startTime = moment(event_data.start_time, "YYYY-MM-DDTHH:mm:ss");
        const endTime = moment(event_data.end_time, "YYYY-MM-DDTHH:mm:ss");
        const duration = moment.duration(endTime.diff(startTime));
        const hours = parseFloat(duration.asHours().toFixed(2));

        setShiftHours(hours);
        formik.setValues({
          ...formik.values,
          date: formattedDate,
          staff_id: event_data.staff_id,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          clock_in_time: formattedClockinTime,
          clock_out_time: formattedClockoutTime,
          clock_in_latitude: event_data.clock_in_latitude,
          clock_in_longitude: event_data.clock_in_longitude,
          total_unpaid_break: event_data.total_unpaid_break,
          total_paid_break: event_data.total_paid_break,
          shift_notes: event_data.shift_notes,
          admin_note: event_data.admin_note,
          is_finished: event_data.is_finished,
          client_id: selectedClient?.value,
          location_id: selectedLocation?.value,
          is_published: event_data.is_published,
        });
        setIsFinishShift(event_data.is_finished || event_data.is_archive);
        setShiftData(event_data);
      } else {
        toast.error(response.message);
        return;
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  };


  const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
    any[]
  >([]);

  const fetchStaffDocumentDetails = async (id: any) => {
    try {
      if (!id) return;
      const response = await getStaffDocumentDetail("", id);
      setStaffDocumentDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenDocument = (path: string) => {
    const fullPath = `${doc_url}/${path}`;
    window.open(fullPath, '_blank');
  };

  const editMaster = async (id: any) => {
    try {
      if (id) {
        const response = await getByStaffDetail(id);
        const data = response.data;
        setStaffViewDetail(data);
      }
    } catch (error) {
      return;
    }
  };

  const handleArchiveShift = async (id: number): Promise<void> => {
    setArchiveId(id);
    setArchiveModal(true);
  };

  const archiveShift = async (id: number) => {
    try {
      const response = await archiveShiftDetail(id);
      fetchEventsFromAPI();
      toast.success(response.message);
      setArchiveModal(false);
      setIsShiftCanvasOpen(false);
    } catch (error) {
      console.error("Error Archive Holday:", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const downloadPatrolInfo = async () => {
    try {
      await exportPatrolLocationInfoDetail(
        editId ? editId : 0
      );
    } catch (error) {
      console.error("Error downloading Patrol Info:", error);
      toast.error("Error downloading Patrol Info. Please try again later.");
    }
  }

  const fetchStaffList = async (location: any, searchValue: string) => {
    try {
      const response = await getScheduleStaffList(location?.value, searchValue);
      setStaffList(response.data);
    } catch (error) {
      console.log(error);

    }
  };
  useEffect(() => {
    if (selectedLocation) {
      fetchStaffList(selectedLocation, staffSearch);
    }
  }, [selectedLocation]);

  // Starting week date
  const [startDate, setStartDate] = useState(moment().startOf('week'));

  // Calculate end date of the week (startDate + 6 days)
  const endDate = moment(startDate).add(6, "days");

  // Function to go to the previous week
  const handlePrevWeek = () => {
    if (pasteMode) {
      paste_standard()
    } else {
      setStartDate(moment(startDate).subtract(7, "days"));
    }
  };

  // Function to go to the next week
  const handleNextWeek = () => {
    if (pasteMode) {
      paste_standard()
    } else {
      setStartDate(moment(startDate).add(7, "days"));
    }
  };

  const handleCurrentWeek = () => {
    if (pasteMode) {
      paste_standard()
    } else {
      setStartDate(moment().startOf('week'));
    }
  };

  useEffect(() => {
    fetchEventsFromAPI();
  }, [selectedClient, startDate, isClear]);

  useEffect(() => {
    fetchClientList(clientSearch);
  }, [clientSearch]);

  /*---------------------------MODAL HANDLER START---------------------------------*/
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const [expLoader, setExpLoader] = useState(false)
  const [publishData, setPublishData] = useState<{ dates: PublishDateItem[], staff_list: PublishStaffItem[], location_list: PublishLocationItem[] }>({ dates: [], staff_list: [], location_list: [] });
  const [isDateAllChecked, setIsDateAllChecked] = useState(false);
  const [checkedPublishDates, setCheckedPublishDates] = useState<string[]>([]);
  const [publishModalForFinished, setPublishModalForFinished] = useState<boolean>(false);

  // Update isChecked state based on the checkedItems array
  useEffect(() => {
    setIsDateAllChecked(checkedPublishDates.length === publishData.dates.length);
  }, [checkedPublishDates, publishData.dates.length]);

  // Handle "Select All" and "Deselect All"
  const handleDateCheckAll = () => {
    if (!isDateAllChecked) {
      setCheckedPublishDates(publishData.dates.map(dateItem => dateItem.date));
    } else {
      setCheckedPublishDates([]);
    }
    setIsDateAllChecked(!isDateAllChecked);
  };

  // Handle individual checkbox changes
  const handleDateCheckboxChange = (date: string) => {
    setCheckedPublishDates(prev =>
      prev.includes(date)
        ? prev.filter(item => item !== date)
        : [...prev, date]
    );
  };

  const [isStaffAllChecked, setIsStaffAllChecked] = useState(false);
  const [checkedPublishStaff, setCheckedPublishStaff] = useState<string[]>([]);

  // Update isChecked state based on the checkedItems array
  useEffect(() => {
    setIsStaffAllChecked(checkedPublishStaff.length === publishData.staff_list.length);
  }, [checkedPublishStaff, publishData.staff_list.length]);

  // Handle "Select All" and "Deselect All"
  const handleStaffCheckAll = () => {
    if (!isStaffAllChecked) {
      setCheckedPublishStaff(publishData.staff_list.map(staffItem => staffItem.staff_id));
    } else {
      setCheckedPublishStaff([]);
    }
    setIsStaffAllChecked(!isStaffAllChecked);
  };

  // Handle individual checkbox changes
  const handleStaffCheckboxChange = (staff_id: string) => {
    setCheckedPublishStaff(prev =>
      prev.includes(staff_id)
        ? prev.filter(item => item !== staff_id)
        : [...prev, staff_id]
    );
  };

  const [isLocationAllChecked, setIsLocationAllChecked] = useState(false);
  const [checkedPublishLocation, setCheckedPublishLocation] = useState<string[]>([]);

  // Update isChecked state based on the checkedItems array
  useEffect(() => {
    setIsLocationAllChecked(checkedPublishLocation.length === publishData.location_list.length);
  }, [checkedPublishLocation, publishData.location_list.length]);

  // Handle "Select All" and "Deselect All"
  const handleLocationCheckAll = () => {
    if (!isLocationAllChecked) {
      setCheckedPublishLocation(publishData.location_list.map(locationItem => locationItem.location_id));
    } else {
      setCheckedPublishLocation([]);
    }
    setIsLocationAllChecked(!isLocationAllChecked);
  };

  // Handle individual checkbox changes
  const handleLocationCheckboxChange = (location_id: string) => {
    setCheckedPublishLocation(prev =>
      prev.includes(location_id)
        ? prev.filter(item => item !== location_id)
        : [...prev, location_id]
    );
  };

  const getPublishDetail = async () => {
    setExpLoader(true);
    try {
      const client: any = selectedClient?.value;
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      const payload = {
        client_id: client,
        start_date: formattedStart,
        end_date: formattedEnd,
      };

      if (pasteMode) {
        paste_standard();
      } else {
        const response = await getSchedulePublishDetailWithLocation(payload);
        if (response.status === 1) {
          setPublishData(response.data);
          const res_date: PublishDateItem[] = response.data.dates;
          const res_staff: PublishStaffItem[] = response.data.staff_list;
          const res_location: PublishLocationItem[] = response.data.location_list;
          setCheckedPublishDates(res_date.map(dateItem => dateItem.date));
          setCheckedPublishStaff(res_staff.map(staff => staff.staff_id));
          setCheckedPublishLocation(res_location.map(location => location.location_id));
          setPublishModal(true);
          setPublishModalForFinished(false);
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setExpLoader(false);
    }
  };

  const bulkShiftFinishAction = async () => {
    setExpLoader(true);
    try {
      const client: any = selectedClient?.value;
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      const payload = {
        client_id: client,
        start_date: formattedStart,
        end_date: formattedEnd,
      };

      if (pasteMode) {
        paste_standard();
      } else {
        const response = await getSchedulePublishShiftList(payload);
        if (response.status === 1) {
          setPublishData(response.data);
          const res_date: PublishDateItem[] = response.data.dates;
          const res_staff: PublishStaffItem[] = response.data.staff_list;
          const res_location: PublishLocationItem[] = response.data.location_list;
          setCheckedPublishDates(res_date.map(dateItem => dateItem.date));
          setCheckedPublishStaff(res_staff.map(staff => staff.staff_id));
          setCheckedPublishLocation(res_location.map(location => location.location_id));
          setPublishModal(true);
          setPublishModalForFinished(true);
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setExpLoader(false);
    }
  };


  const [publishLoader, setPublishLoader] = useState(false)

  const handlePublishShifts = async () => {
    setPublishLoader(true)
    try {
      const client: any = selectedClient?.value;

      const payload = {
        client_id: client,
        dates: checkedPublishDates,
        staff_ids: checkedPublishStaff,
        location_ids: checkedPublishLocation,
      }
      const response = publishModalForFinished? await finishedBulkShift(payload) : await publishShiftsWithLocation(payload);
      if (response.status === 1) {
        toast.success(response.message);
        setPublishModal(false);
        fetchEventsFromAPI();        
      } else {
        toast.error(response.message);
      }
      setPublishLoader(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setPublishLoader(false);;
    }
  };

  /*---------------------------MODAL HANDLER END-------------------------------- */

  const exportExcel = async () => {
    try {
      const client: any = selectedClient?.value;
      const locationIds: any = selectedFilterLocation.map((location: any) => location.value);
      const locationIdsCSV = locationIds.join(',');
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      await ExportShift(client, locationIdsCSV, formattedStart, formattedEnd);
    } catch (error) {
      console.log(error);
    }
  };

  const checkpointlog = () => {
    window.location.href = '/checkpoint-log';
  };
  /* New End */

  const [pasteMode, setPasteMode] = useState(false);
  const childRef = useRef<any>(null);
  const callRemovePasteData = () => {
    if (childRef.current) {
      childRef.current.triggerRemovePasteData();
    }
  };

  const callSavePasteData = async () => {
    if (childRef.current) {
      const formattedEvents = childRef.current.triggerSavePasteData();
      try {
        const response = await pasteScheduleShift(formattedEvents);
        if (response.status === 1) {
          toast.success(response.message);
          fetchEventsFromAPI();
          callRemovePasteData()
        } else {
          toast.error(response.message);
          return;
        }
      } catch (error) {
        toast.error("Error sending data. Please try again later.");
        console.error("API Error:", error);
      }
    }
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Schedule" pageTitle="Dashboard" />
            {
              loading ?
                <Row className="placeholder-glow">
                  <Col md={3} lg={2}>
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                  </Col>
                  <Col md={9} lg={10}>
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                  </Col>
                </Row>
                :
                <Row className="schedule">
                  <Col lg={12}>
                    <Row>
                      {/* <Col md={3} lg={2}>
                      <Card className="schedule-card">
                        <Card.Body>
                          <Row>
                            <Col lg={12}>
                              <Dropdown className="mb-3 w-100">
                                <Dropdown.Toggle variant="light" className="w-100">
                                  Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {totalShifts > 0 ?
                                    <Dropdown.Item href="#" onClick={() => setOpenTemplateModal(true)}> <i className="ri-download-2-line"></i>  Save Template</Dropdown.Item> : null
                                  }
                                  <Dropdown.Item href="#" onClick={loadShiftTemplateFunc}> <i className="ri-upload-2-line"></i> Load Template</Dropdown.Item>
                                  <Dropdown.Item href="#" onClick={exportExcel}> <i className="ri-file-excel-2-line"></i> Export Excel</Dropdown.Item>
                                  <Dropdown.Item href="#" onClick={checkpointlog}> <i className="ri-login-box-line"></i> Go To Checkpoint</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              <Dropdown className="mb-4">
                                <Dropdown as={ButtonGroup} size="lg" className="w-100">
                                  {expLoader ? (
                                    <Button
                                      color="primary"
                                      // className="clear-btn"
                                      style={{ height: '71.78px', width: '197px' }}
                                      disabled
                                    >
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    </Button>
                                  ) : (
                                    <Button variant="primary" onClick={() => getPublishDetail()}>
                                      <h5 className="text-white">Publish</h5>
                                      <small>{totalUnpublishShifts ?? 0} Shifts Unpublished</small>
                                    </Button>
                                  )}
                                  <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => getPublishDetail()}>Publish & Notify</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Resend Notifications</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Form.Label
                                htmlFor="choices-multiple-remove-button"
                                className="text-muted"
                              >
                                Client
                              </Form.Label>
                              <Select
                                closeMenuOnSelect={true}
                                value={selectedClient}
                                options={clientList}
                                // styles={customStyles}
                                onChange={(newValue: any) => {
                                  setSelectedClient(newValue);
                                  setSelectedLocation(null)
                                  localStorage.setItem('selectedLocation', JSON.stringify(null));
                                  localStorage.setItem('selectedClient', JSON.stringify(newValue));
                                }}
                                onInputChange={(newValue: string) => setClientSearch(newValue)}
                              />
                            </div>
                          </Col>
                          <div className="mt-4 mb-3">
                            <Card.Title as="h6" className="mb-0">Weekly Report</Card.Title>
                          </div>
                          <ul className="vstack gap-2 list-unstyled">
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-pin-map"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Total Locations</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalLocation}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-clock"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Total Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalShifts}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-pin"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Un-Assigned Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalUnassignShifts}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-pin"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">UnPublished Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalUnpublishShifts ?? 0}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-pin"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Published Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalPublishShifts}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-trash3"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Archived Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalArchivedShifts ?? 0}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <Row>
                            <Col md={12}>
                              <Button className="next-btn w-100 mb-2" onClick={() => exportExcel()}>
                                <i className="ri-file-excel-2-line"></i> Export
                              </Button>
                            </Col>
                            <Col md={12}>
                              <Button className="next-btn w-100" onClick={() => checkpointlog()}>
                                <i className="ri-file-excel-2-line"></i> Checkpoint
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                      </Col> */}
                      <Col md={12} lg={12}>
                        <Card className="schedule-card mb-1">
                          <Card.Body>
                            <Row>
                              <Col lg={6}>
                                <div className="d-inline-flex">
                                  <Select
                                    closeMenuOnSelect={true}
                                    value={selectedClient}
                                    options={clientList}
                                    styles={{
                                      control: (provided: any, state: any) => ({
                                        ...provided,
                                        height: '40px',
                                        minHeight: '40px',
                                        minWidth: '250px',
                                        borderColor: state.isFocused ? '#ED1B24' : provided.borderColor, // Change border color on focus
                                        boxShadow: state.isFocused ? '0 0 0 0.2px #ED1B24' : provided.boxShadow, // Optional: Add box-shadow on focus
                                        '&:hover': {
                                          borderColor: state.isFocused ? '#ED1B24' : provided.borderColor, // Keep hover consistent with focus
                                        }
                                      }),
                                      option: (provided: any, state: any) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected ? '#ED1B24' : provided.backgroundColor, // Change background when option is selected
                                        color: state.isSelected ? '#fff' : provided.color, // Optional: Set color when selected
                                        '&:hover': {
                                          backgroundColor: state.isSelected ? '#404040' : '#ffe9e9', // Change background color on hover
                                        },
                                      }),
                                      menuList: (provided: any) => ({
                                        ...provided,
                                        '::-webkit-scrollbar': {
                                          width: '6px',
                                        },
                                        '::-webkit-scrollbar-thumb': {
                                          background: 'lightgray', // Customize scrollbar color
                                          borderRadius: '3px',
                                        },
                                        '::-webkit-scrollbar-thumb:hover': {
                                          background: 'rgba(0, 0, 0, 0.5)', // Change color on hover
                                        },
                                        '::-webkit-scrollbar-track': {
                                          background: 'rgba(0, 0, 0, 0.1)', // Customize scrollbar track
                                        },
                                      }),
                                    }}
                                    onChange={(newValue: any) => {
                                      if (pasteMode) {
                                        // Call paste_standard logic if pasteMode is true
                                        paste_standard();
                                      } else {
                                        // Standard logic when pasteMode is false
                                        setSelectedFilterLocation([]);
                                        setSelectedLocation(null);
                                        setSelectedClient(newValue);
                                        localStorage.setItem('selectedClient', JSON.stringify(newValue));
                                      }
                                    }}
                                    onInputChange={(newValue: string) => setClientSearch(newValue)}
                                  />
                                  <div className="d-flex align-items-center ms-2">
                                    <Button variant="primary" onClick={handlePrevWeek} size="sm" className="p-1">
                                      <i className="ri-arrow-left-s-line" style={{ fontSize: "20px" }} />
                                    </Button>

                                    <div className="mx-2" style={{ fontSize: "1rem", fontWeight: "600" }}>
                                      {startDate.format("MMM D")} – {endDate.format("D MMM, YYYY")}
                                    </div>

                                    <Button variant="primary" onClick={handleNextWeek} size="sm" className="p-1">
                                      <i className="ri-arrow-right-s-line" style={{ fontSize: "20px" }} />
                                    </Button>
                                    <Button variant="light" onClick={handleCurrentWeek} className="p-2" style={{ marginLeft: '8px' }}>
                                      Today
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6} className="d-flex justify-content-end">
                                {pasteMode ?
                                  <div className="btn-section">
                                    <Button
                                      variant="primary"
                                      onClick={callSavePasteData}
                                    >
                                      <i className="ri-check-line align-baseline me-1" style={{ fontWeight: 'bolder' }}></i>
                                    </Button>
                                    <Button
                                      variant="light"
                                      onClick={callRemovePasteData}
                                    >
                                      <i className="ri-close-line align-baseline me-1" style={{ fontWeight: 'bolder' }}></i>
                                    </Button>
                                  </div> :
                                  <div className="btn-section">
                                  <Button
                                    className="filter-btn"
                                    onClick={() => FilterOpen()}
                                  >
                                    <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
                                    Filters
                                  </Button>
                                  <Button variant="primary" onClick={() => getPublishDetail()} className="p-2">
                                    Publish ({totalUnpublishShifts ?? 0})
                                  </Button>
                                  <Dropdown className="ms-2">
                                    <Dropdown.Toggle variant="light" className="w-100" disabled={pasteMode}>
                                      Actions
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {totalShifts > 0 ?
                                        <Dropdown.Item href="#" onClick={() => setOpenTemplateModal(true)}> <i className="ri-download-2-line"></i>  Save Template</Dropdown.Item> : null
                                      }
                                      <Dropdown.Item href="#" onClick={loadShiftTemplateFunc}> <i className="ri-upload-2-line"></i> Load Template</Dropdown.Item>
                                      <Dropdown.Item href="#" onClick={exportExcel}> <i className="ri-file-excel-2-line"></i> Export Excel</Dropdown.Item>
                                      {totalPublishShifts ?
                                      <Dropdown.Item href="#" onClick={bulkShiftFinishAction}> <i className="ri-stack-line"></i> Complete Shifts</Dropdown.Item>
                                      :''
                                      }
                                      <Dropdown.Item href="#" onClick={checkpointlog}> <i className="ri-login-box-line"></i> Checkpoint Logs</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                }
                              </Col>
                              {/* <Col lg={12} className="line"></Col> */}
                              <Col lg={12} className="schedule-table mt-2" style={{ overflow: 'auto' }}>
                                <ScheduleWeekTableNew
                                  ref={childRef}
                                  openCreateShift={createShiftShow}
                                  openShiftDetail={handleEventClick}
                                  eventData={eventList}
                                  eventDates={eventDates}
                                  selectedClient={selectedClient}
                                  handleEventDrop={handleEventDrop}
                                  handlePasteMode={(e: any) => setPasteMode(e)}
                                />
                              </Col>
                              <Col lg={12}>
                                <Row>
                                  <Col lg={7} className="legends d-flex flex-wrap">
                                    <span className="p-2 rounded">
                                      <i className="ri-checkbox-blank-circle-fill text-secondary align-bottom me-1"></i>
                                      Un-assigned Shifts ({totalUnassignShifts ?? 0})
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-checkbox-blank-circle-fill align-bottom me-1"
                                        style={{ color: "#F0B414" }}
                                      ></i>
                                      Unpublished Shifts ({totalUnpublishShifts ?? 0})
                                    </span>
                                    <span className="p-2 rounded">
                                      <i className="ri-checkbox-blank-circle-fill text-success align-bottom me-1"></i>
                                      Finished Shifts ({totalFinishShifts ?? 0})
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-checkbox-blank-circle-fill align-bottom me-1"
                                        style={{ color: "#3981EC" }}
                                      ></i>
                                      Published Shifts ({totalPublishShifts ?? 0})
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-checkbox-blank-circle-fill align-bottom me-1"
                                        style={{ color: "#FF6C6C" }}
                                      ></i>
                                      Archived Shifts ({totalArchivedShifts ?? 0})
                                    </span>
                                  </Col>
                                  <Col lg={5} className="legends d-flex flex-wrap justify-content-end">
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-timer-flash-line align-bottom me-1 text-success"
                                      ></i>
                                      Clocked In
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-alarm-warning-line align-bottom me-1 text-primary"
                                      ></i>
                                      Missed Clock In
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-thumb-down-line align-bottom me-1 text-primary"
                                      ></i>
                                      Rejected
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-scan-line align-bottom me-1 text-warning"
                                      ></i>
                                      Pending
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
            }
          </Container>
        </div>
        <Offcanvas
          className="filter-canvas"
          show={shiftFilters}
          placement="end"
          onHide={() => setShiftFilters(false)}
        >
          <Offcanvas.Header
            id="shiftFilters"
            className="border-bottom filter-canvas"
            closeButton
          >
            <Offcanvas.Title id="courseFiltersLabel">
              {" "}
              <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
              Filters
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Row>
                <Col lg={12}>
                  {/* <Row className="filter-2">
                    <Col lg={12} className="d-flex justify-content-between">
                      <h5>Shifts</h5>
                      <p style={{ cursor: 'pointer' }} onClick={handleClearAllStatus}>Clear All</p>
                    </Col>
                    <Col lg={12}>
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="AllStatus"
                          checked={selectedStatus.length === allStatusOptions.length}
                          onChange={handleAllStatusChange}
                        />
                        <label className="form-check-label" htmlFor="AllStatus">
                          All
                        </label>
                      </div>
                    </Col>
                    {allStatusOptions.map((status) => (
                      <Col lg={12} key={status}>
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`statusCheckbox${status}`}
                            checked={selectedStatus.includes(status)}
                            onChange={() => handleStatusChange(status)}
                          />
                          <label className="form-check-label" htmlFor={`statusCheckbox${status}`}>
                            {status}
                          </label>
                        </div>
                      </Col>
                    ))}
                  </Row> */}
                  <Row className="filter-2">
                    <Col lg={12}>
                      <h5>Locations</h5>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-icon">
                        <div className={`react-select-container`}>
                          <Select
                            options={filterLocationList}
                            isMulti
                            value={selectedFilterLocation}
                            onChange={(newValue: any) => setSelectedFilterLocation(newValue || [])}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                            placeholder="Select Locations..."
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Offcanvas.Body>
          <Row>
            <Col lg="12">
              <div className="offcanvas-footer border-top-0 p-3 text-center">
                <Row className="px-2 approve-reject-btn">
                  <Col lg={6} className="clear-section">
                    <Button onClick={ClearAllFilter} color="primary" className="clear-btn">
                      Clear Filters
                    </Button>
                  </Col>
                  <Col lg={6} className="Apply-section">
                    <Button color="primary" className="next-btn" onClick={() => { setLoading(true); fetchEventsFromAPI(); setShiftFilters(false) }}>
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Offcanvas>
        <Offcanvas
          className="shift-option-canvas"
          show={isShiftCanvasOpen}
          placement="end"
          onHide={() => setIsShiftCanvasOpen(false)}
        >

          <Row className="m-0">
            <Col lg={3} className="shift-column">
              {selectedStaffName &&
                <>
                  <span>{getInitials(selectedStaffName)}</span>
                  <h4 className="title">{selectedStaffName}</h4>
                </>
              }
              <Row className="g-3 mt-3">
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Client</p>
                        <h6 className="fs-lg">{selectedClient?.label}</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Location</p>
                        <h6 className="fs-lg">{selectedLocation?.label}</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                {editId &&
                  <Col md={12}>
                    <Card className="border shadow-none mb-0">
                      <Card.Body className="p-2">
                        <div className="text-center">
                          <p className="text-muted text-truncate mb-2">Created By</p>
                          <h6 className="fs-lg">{Created_by}</h6>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                }
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Shift Length</p>
                        <h6 className="fs-lg">{shiftHours} Hrs</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                {/* <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Shift Cost</p>
                        <h6 className="fs-lg">$0</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                {(editId != null && editId > 0 && !isFinishShift) && (
                  <Col md={12}>
                    <Button
                      variant="outline-primary"
                      className="w-100"
                      onClick={() => handleArchiveShift(editId)}
                    >
                      <i className="ri-delete-bin-line"></i> Delete
                    </Button>
                  </Col>
                )}
                {(Is_petrol_site === 1 && editId) &&
                  <Col md={12}>
                    <Button onClick={downloadPatrolInfo} variant="primary" className="sub-update-btn d-flex align-items-center gap-2">
                      <i className=" ri-download-line" style={{ fontSize: '18px' }}></i> Patrol Info
                    </Button>
                  </Col>
                }
              </Row>
            </Col>
            <Col lg={9} className="p-0 shift-form">
              <Form onSubmit={formik.handleSubmit}>
                <Offcanvas.Header
                  id="createShift"
                  className="border-bottom"
                  closeButton
                >
                  <Offcanvas.Title id="createShiftLabel">
                    {" "}
                    <i
                      className="bx bx-calendar-plus align-middle me-1"
                      style={{ color: "#999" }}
                    ></i>{" "}
                    {editId ? (isFinishShift) ? "Shift Detail" : "Edit Shift" : "New Shift"}   {(Is_petrol_site === 1 && editId) && <span className="badge bg-info-subtle text-info">Patrol Shift</span>}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0 px-4">
                  <Tab.Container
                    defaultActiveKey="home1"
                    activeKey={activeTab}
                    onSelect={handleTabChange}
                  >
                    {
                      (editId != null && editId > 0) &&
                      <Nav as="ul" variant='pills' className="nav-justified mt-3 mb-3">
                        <Nav.Item as="li">
                          <Nav.Link eventKey="home1">
                            Details
                          </Nav.Link>
                        </Nav.Item>
                        {editId &&
                          <Nav.Item as="li">
                            <Nav.Link eventKey="profile1">
                              History
                            </Nav.Link>
                          </Nav.Item>
                        }
                        {(editId && StaffViewDetail) &&
                          <Nav.Item as="li">
                            <Nav.Link eventKey="profile2">
                              Staff Details
                            </Nav.Link>
                          </Nav.Item>
                        }
                        {(editId && shiftData.overlapping.length > 0) &&
                          <Nav.Item as="li">
                            <Nav.Link eventKey="overlapDetail">
                              Overlapping
                            </Nav.Link>
                          </Nav.Item>
                        }
                      </Nav>
                    }

                    <Tab.Content className="text-muted">
                      <Tab.Pane eventKey="home1">
                        <SimpleBar
                          style={{ maxHeight: "80vh" }}
                          className="px-3"
                        >
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3 mt-3">
                                <Form.Label>Date</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control type="text" className="form-control form-control-icon" placeholder="Enter Date" name="shift_date" value={formik.values.date} readOnly />
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-calendar-event-line"
                                  ></i>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="12">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Select Staff
                                </Form.Label>
                                <Select
                                  inputId="staffSelect"
                                  name="staff_id"
                                  options={staffList}
                                  value={staffList.find(option => option.value === formik.values.staff_id)}
                                  onChange={(selectedOption: any) => {
                                    formik.setFieldValue('staff_id', selectedOption ? selectedOption.value : '');
                                    setSelectedStaffName(selectedOption ? selectedOption.label : '');
                                  }}
                                  onBlur={formik.handleBlur}
                                  isDisabled={shiftClockIn}
                                  classNamePrefix="react-select"
                                  className={formik.touched.staff_id && formik.errors.staff_id ? 'is-invalid' : ''}
                                />
                                <i
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "1.6rem",
                                  }}
                                  className="ri-user-line"
                                ></i>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.staff_id}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3">
                                <Form.Label>Start Time</Form.Label>
                                <div className="position-relative form-icon">
                                  <Cleave
                                    placeholder="hh:mm"
                                    options={{
                                      time: true,
                                      timePattern: ['h', 'm']
                                    }}
                                    value={formik.values.start_time}
                                    onChange={(e: any) => handleTimeChange(e.target.rawValue, "start_time")}
                                    className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                    disabled={shiftClockIn}
                                  />
                                  {/* <Flatpickr
                                className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                name="start_time"
                                value={formik.values.start_time}
                                onChange={(time: Date[]) => handleTimeChange(time, "start_time")}
                                style={{ height: "45px" }}
                                placeholder="Select Start Time"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  time_24hr: true
                                }}
                              /> */}
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-time-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.start_time}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3">
                                <Form.Label>End Time</Form.Label>
                                <div className="position-relative form-icon">
                                  <Cleave
                                    placeholder="hh:mm"
                                    options={{
                                      time: true,
                                      timePattern: ['h', 'm']
                                    }}
                                    value={formik.values.end_time}
                                    disabled={shiftClockIn}
                                    onChange={(e: any) => handleTimeChange(e.target.rawValue, "end_time")}
                                    className={`form-control form-control-lg form-control-icon ${formik.touched.end_time && formik.errors.end_time ? 'is-invalid' : ''}`}
                                  />
                                  {/* <Flatpickr
                                className={`form-control form-control-lg form-control-icon ${formik.touched.end_time && formik.errors.end_time ? 'is-invalid' : ''}`}
                                name="end_time"
                                value={formik.values.end_time}
                                onChange={(time: Date[]) => handleTimeChange(time, "end_time")}
                                style={{ height: "45px" }}
                                placeholder="Select End Time"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  time_24hr: true
                                }}
                              /> */}
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-time-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.end_time}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Unpaid Break (in min)
                                </Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control type="number" className="form-control form-control-icon" placeholder="Enter Time"
                                    name="total_unpaid_break"
                                    onChange={e => {
                                      formik.handleChange(e);
                                      const shiftHours = calculateShiftHours(formik.values.start_time, formik.values.end_time, Number(e.target.value))
                                      setShiftHours(shiftHours);
                                    }}
                                    disabled={shiftClockIn}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.total_unpaid_break}
                                    isInvalid={!!(formik.touched.total_unpaid_break && formik.errors.total_unpaid_break)}
                                  />
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-calendar-2-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.total_unpaid_break}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Paid Break (in min)
                                </Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control type="number" className="form-control form-control-icon" placeholder="Enter Time"
                                    name="total_paid_break"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    disabled={shiftClockIn}
                                    value={formik.values.total_paid_break}
                                    isInvalid={!!(formik.touched.total_paid_break && formik.errors.total_paid_break)}
                                  />
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-calendar-2-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.total_paid_break}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>

                            {
                              (editId != null && editId > 0 && shiftData?.is_published) ?
                              <>
                                <Col lg="6">
                                  <Form.Group className="mb-3">
                                    <Form.Label>Clocked In Time</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Cleave
                                        placeholder="hh:mm"
                                        options={{
                                          time: true,
                                          timePattern: ['h', 'm']
                                        }}
                                        value={formik.values.clock_in_time}
                                        onChange={(e: any) => handleTimeChange(e.target.rawValue, "clock_in_time")}
                                        disabled={shiftClockIn}
                                        className={`form-control form-control-lg form-control-icon ${formik.touched.clock_in_time && formik.errors.clock_in_time ? 'is-invalid' : ''}`}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-time-line"
                                      ></i>
                                      <Form.Control.Feedback type="invalid">
                                        {formik.errors.clock_in_time}
                                      </Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="6">
                                  <Form.Group className="mb-3">
                                    <Form.Label>Clocked Out Time</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Cleave
                                        placeholder="hh:mm"
                                        options={{
                                          time: true,
                                          timePattern: ['h', 'm']
                                        }}
                                        value={formik.values.clock_out_time}
                                        disabled={shiftClockOut}
                                        onChange={(e: any) => handleTimeChange(e.target.rawValue, "clock_out_time")}
                                        className={`form-control form-control-lg form-control-icon ${formik.touched.clock_out_time && formik.errors.clock_out_time ? 'is-invalid' : ''}`}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-time-line"
                                      ></i>
                                      <Form.Control.Feedback type="invalid">
                                        {formik.errors.clock_out_time}
                                      </Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="6">
                                  <Form.Group className="mb-3">
                                    <Form.Label>Clock in Latitude/Longitude</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type="text"
                                        name="clock_in_latitude"
                                        className="form-control-lg form-control-icon name-input"
                                        value={`${formik.values.clock_in_latitude ? formik.values.clock_in_latitude : "-"}/${formik.values.clock_in_longitude ? formik.values.clock_in_longitude : "-"}`}
                                        disabled
                                      />
                                      <i style={{ fontSize: '16px' }} className=" ri-map-pin-line"></i>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="6">
                                  <Form.Group className="mb-3">
                                    <Form.Label>Clock out Latitude/Longitude</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type="text"
                                        name="clock_in_longitude"
                                        className="form-control-lg form-control-icon name-input"
                                        value={`${formik.values.clock_out_latitude ? formik.values.clock_out_latitude : "-"}/${formik.values.clock_out_longitude ? formik.values.clock_out_longitude : "-"}`}
                                        disabled
                                      />
                                      <i style={{ fontSize: '16px' }} className=" ri-map-pin-line"></i>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="12">
                                  <Form.Group className="mb-3 form-icon">
                                    <Form.Label className="text-muted">
                                      Is Finished
                                    </Form.Label>
                                    <Form.Select
                                      className="form-select-lg form-control-icon"
                                      id="is_finished"
                                      onChange={(e) => formik.handleChange(e)}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.is_finished}
                                    >
                                      <option value="0">No</option>
                                      <option value="1">Yes</option>
                                    </Form.Select>
                                    <i
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "1.6rem",
                                      }}
                                      className="ri-user-line"
                                    ></i>
                                  </Form.Group>
                                </Col>
                              </>
                            :''}
                            <Col lg="12">
                              <Form.Group className="mb-3">
                                <Form.Label>Note</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control
                                    as="textarea"
                                    className="form-control-lg form-control-icon name-input"
                                    placeholder="Enter Notes"
                                    rows={2}
                                    name="shift_notes"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.shift_notes}
                                    isInvalid={!!(formik.touched.shift_notes && formik.errors.shift_notes)}
                                  />
                                  <i
                                    style={{
                                      fontSize: "16px",
                                      alignItems: "baseline",
                                      marginTop: "10px",
                                    }}
                                    className="ri-book-open-line"
                                  ></i>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.shift_notes}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg="12">
                              <Form.Group className="mb-3">
                                <Form.Label>Admin Note</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control
                                    as="textarea"
                                    className="form-control-lg form-control-icon name-input"
                                    placeholder="Enter Admin Notes"
                                    rows={2}
                                    name="admin_note"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.admin_note}
                                    isInvalid={!!(formik.touched.admin_note && formik.errors.admin_note)}
                                  />
                                  <i
                                    style={{
                                      fontSize: "16px",
                                      alignItems: "baseline",
                                      marginTop: "10px",
                                    }}
                                    className="ri-book-open-line"
                                  ></i>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.admin_note}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          {!isFinishShift &&
                            <Row>
                              <div className="line"></div>
                              <Col lg={12} className="d-flex justify-content-between">
                                {/* <div className="col-sm-auto">
                                        <Form.Label
                                          className="visually-hidden"
                                          htmlFor="autoSizingSelect"
                                        >
                                          Preference
                                        </Form.Label>
                                        <Form.Select
                                          data-choices
                                          data-choices-sorting="true"
                                          id="autoSizingSelect"
                                        >
                                          <option value="1">Repeat Tomorrow</option>
                                          <option value="2">Repeat for next week</option>
                                          <option value="3">Repeat for Specific days</option>
                                          <option value="3">Publish shift</option>
                                          {editId && (
                                            <option value="5">Terminate Shift</option>
                                          )}
                                        </Form.Select>
                                      </div> */}
                                {formik.isSubmitting ? (
                                  <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="submit"
                                    className="next-btn"
                                    disabled={formik.isSubmitting}
                                  >
                                    {editId ? "Update" : "Create"}
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          }
                        </SimpleBar>
                      </Tab.Pane>
                      {editId &&
                        <Tab.Pane eventKey="profile1">
                          <SimpleBar
                            style={{ maxHeight: "85vh" }}
                            className="px-3"
                          >
                            <Row>
                              {ShiftHistoryDetails === null ?
                                <Col lg={12} className="text-center mt-5 pt-5">
                                  <img src={noDataImg} style={{ width: '100px' }} />
                                  <div className='d-flex justify-content-center'>No history available</div>
                                </Col>
                                :
                                <Col lg="12" className="status-table">
                                  <Table className="align-middle table-wrap mb-0" responsive>
                                    <thead>
                                      <tr>
                                        <th scope="col User">User</th>
                                        <th scope="col User">Field</th>
                                        <th scope="col Previous">Previous Value</th>
                                        <th scope="col Previous">Updated Value</th>
                                        <th scope="col Previous">Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ShiftHistoryDetails?.map((time: any, index: number) => {
                                        return (
                                          <tr key={index}>
                                            <td>{time?.user_name}</td>
                                            <td>{time?.field}</td>
                                            <td>{time?.prev_val}</td>
                                            <td>{time?.updated_val}</td>
                                            <td>{time?.created_date}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </Table>
                                </Col>
                              }
                            </Row>
                          </SimpleBar>
                        </Tab.Pane>
                      }
                      {editId &&
                        <Tab.Pane eventKey="profile2">
                          {!StaffViewDetail && (
                            <Col lg={12} className="text-center mt-5 pt-5">
                              <img src={noDataImg} style={{ width: '100px' }} />
                              <div className='d-flex justify-content-center'>Shift not assigned yet</div>
                            </Col>
                          )}
                          {StaffViewDetail && (
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 position-relative">
                                {StaffViewDetail?.avatar ? (
                                  <img src={imageSrc} alt="" className="avatar-sm rounded" />
                                ) : (
                                  <div className="avatar-sm">
                                    <div className="avatar-title rounded bg-primary-subtle text-primary">
                                      {getInitials(StaffViewDetail?.employee_name || "-")}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h5 className="fs-md mb-0">
                                  <Link to="/pages-profile" className="text-dark">
                                    {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  {StaffViewDetail?.access_level || "-"}
                                </p>
                                <p className="text-muted mb-0">
                                  {StaffViewDetail?.employee_status || "-"}
                                </p>
                              </div>
                            </div>
                          )}

                          {/* Contact Details */}
                          {StaffViewDetail && (
                            <div className="mt-4">
                              <p className="text-muted mb-2">
                                <i className="bi bi-telephone align-baseline me-1"></i>
                                {StaffViewDetail?.mobile_no ? (
                                  <Link
                                    to={`tel:${StaffViewDetail.mobile_no}`}
                                    className="text-muted"
                                    style={{ paddingLeft: "4px" }}
                                  >
                                    {StaffViewDetail.mobile_no}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </p>
                              <p className="text-muted mb-2">
                                <i className="bi bi-envelope align-baseline me-1"></i>
                                {StaffViewDetail?.email ? (
                                  <Link
                                    to={`mailto:${StaffViewDetail.email}`}
                                    className="text-muted"
                                    style={{ paddingLeft: "4px" }}
                                  >
                                    {StaffViewDetail.email}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </p>
                              <p className="text-muted mb-2">
                                <i className="bi bi-geo-alt align-baseline me-1"></i>
                                {StaffViewDetail?.address || "-"}
                              </p>
                              <p className="text-muted mb-2">
                                <i className="mdi mdi-gender-transgender align-baseline me-1"></i>
                                {StaffViewDetail?.gender || "-"}
                              </p>
                              <p className="text-muted mb-0">
                                <i className="ri-cake-line align-baseline me-1"></i>
                                {StaffViewDetail?.dob || "-"}
                              </p>
                            </div>
                          )}

                          {/* License Section */}
                          {StaffDocumentDetail?.filter((doc) => doc.document_type === "Licenses").length > 0 && (
                            <div className="border-bottom border-top border-dashed py-4 mt-4">
                              <h5 className="card-title mb-3">License</h5>
                              <div className="vstack gap-3">
                                {StaffDocumentDetail
                                  .filter((doc) => doc.document_type === "Licenses")
                                  .map((doc, index) => (
                                    <div key={index} className="d-flex gap-2 align-items-center position-relative">
                                      <div className="flex-shrink-0">
                                        <div className="avatar-sm border border rounded">
                                          <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                                            <i className="bi bi-file-pdf"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h6>License No: {doc.ref_no}</h6>
                                        <p className="text-muted mb-0">Expiry Date: {doc.expiry_date || "-"}</p>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <Link
                                          to={`#`}
                                          onClick={() => handleOpenDocument(doc.document_path)}
                                          className="icon-link text-reset stretched-link fs-xl"
                                          style={{ transform: "translate3d(0, -.125rem, 0)" }}
                                        >
                                          <i className="ri-eye-line link-info"></i>
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </Tab.Pane>
                      }
                      {(editId && shiftData.overlapping.length > 0) &&
                        <Tab.Pane eventKey="overlapDetail">
                          <div className="mt-4">
                            <ul className="list-group">
                              {shiftData.overlapping.map((overlap:any, index:number) => (
                                <li key={index} className="list-group-item">
                                  <strong>Location:</strong> {overlap.location_name} <br />
                                  <strong>Date:</strong> {overlap.date} <br />
                                  <strong>Shift Time:</strong> {overlap.shift_time}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Tab.Pane>
                      }
                    </Tab.Content>
                  </Tab.Container>
                </Offcanvas.Body>
              </Form>
            </Col>
          </Row>
        </Offcanvas>
        <Modal
          show={openTemplateModal}
          onHide={() => {
            setOpenTemplateModal(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
            <h4>Save Template for {selectedClient?.label}</h4>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form onSubmit={templateFormik.handleSubmit}>
              <Row>
                <Col md="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Template Name</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        name="template_name"
                        className="form-control-lg form-control-icon name-input"
                        placeholder="Enter Template Name"
                        type="text"
                        onChange={templateFormik.handleChange}
                        onBlur={templateFormik.handleBlur}
                        value={templateFormik.values.template_name}
                        isInvalid={!!(templateFormik.touched.template_name && templateFormik.errors.template_name)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {templateFormik.errors.template_name}
                      </Form.Control.Feedback>
                      <i style={{ fontSize: "16px" }} className="bx bx-note"></i>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        name="description"
                        as="textarea"
                        className="form-control-lg form-control-icon name-input"
                        placeholder="Enter Description"
                        type="text"
                        onChange={templateFormik.handleChange}
                        onBlur={templateFormik.handleBlur}
                        value={templateFormik.values.description}
                        rows={5}
                      />
                      <i style={{ fontSize: "16px" }} className="bx bx-file"></i>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_remove_staff"
                        id="is_remove_staff"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_remove_staff", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_remove_staff)}
                      />
                      <Form.Label>
                        Remove Staff From Shifts
                      </Form.Label>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_shift_notes"
                        id="is_shift_notes"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_shift_notes", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_shift_notes)}
                      />
                      <Form.Label>
                        Include Shift Notes
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_admin_notes"
                        id="is_admin_notes"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_admin_notes", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_admin_notes)}
                      />
                      <Form.Label>
                        Include Admin Notes
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setOpenTemplateModal(false)}>Cancel</Button>
              <Button variant="danger" onClick={() => templateFormik.handleSubmit()} disabled={templateFormik.isSubmitting}>
                {templateFormik.isSubmitting ?
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  ><span className="visually-hidden">Submitting...</span></Spinner> :
                  <><i className="ri-upload-2-fill"></i> Submit</>
                }
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={openLoadTemplateModal}
          onHide={() => {
            setOpenLoadTemplateModal(false);
          }}
          size="lg"
          centered
        >
          {
            submitTemplateResponse ?
              <>
                <Modal.Body className="p-4">
                  <Row className="g-4">
                    <Col md={12}>
                      <h3 className="mb-4">{submitTemplateResponse?.total_shifts} Shifts in Template</h3>
                      <span className="flex-grow-1">
                        <h6 className="text-muted mb-0 mt-2">
                          <i className="ri-check-line text-success align-middle me-1" style={{ fontSize: "1.2rem" }}></i> {submitTemplateResponse?.successful_shifts}
                        </h6>
                      </span>
                      {
                        (submitTemplateResponse?.issues != "") &&
                        <>
                          <span className="flex-grow-1">
                            <h6 className="text-muted mb-0 mt-2">
                              <i className="ri-alert-line text-danger align-middle me-1" style={{ fontSize: "1.2rem" }}></i> {submitTemplateResponse?.issues}
                            </h6>
                          </span>
                          {
                            (submitTemplateResponse.overlaps.length > 0) &&
                            <Accordion defaultActiveKey='0' className="custom-accordion-border accordion-border-box accordion-danger mt-3">
                              <Accordion.Item eventKey='0'>
                                <Accordion.Header>
                                  {submitTemplateResponse.overlaps.length} Shift has overlapped with existing shift.
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {
                                      submitTemplateResponse.overlaps.map((overlapItem: any, index: number) => (
                                        <li key={index}>
                                          {overlapItem}
                                        </li>
                                      ))
                                    }
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          }
                        </>
                      }
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <div className="hstack gap-2 justify-content-center">
                    <Button variant="primary" onClick={() => setOpenLoadTemplateModal(false)}>Okay, Done</Button>
                  </div>
                </Modal.Footer>
              </>
              : templateData.length > 0 ?
                <>
                  <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
                    <h4>Load Template for {selectedClient?.label}</h4>
                  </Modal.Header>
                  <Modal.Body className="p-4">
                    <SimpleBar
                      style={{ maxHeight: "65vh" }}
                      className="px-3"
                    >
                      <Row className="g-4">
                        {templateData.map((dataItem: any, index: number) => (
                          <Col lg={12} key={index}>
                            <Form.Check className="card-radio">
                              <Form.Check.Input id={`template_chk${index}`} name="shippingMethod" type="radio" checked={dataItem.id === template_id} onChange={() => setTemplateId(dataItem.id)} />
                              <Form.Check.Label className="d-flex gap-2 align-items-center" htmlFor={`template_chk${index}`}>
                                <span className="flex-grow-1">
                                  <span className="fs-md fw-medium mb-1 text-wrap d-block">{dataItem.template_name}</span>
                                  <p className="text-muted mb-0 mt-2">
                                    <i className="bi bi-calendar-event align-baseline me-1"></i> {dataItem.total_shift}
                                    <i className="bi bi-people-fill align-baseline ms-4 me-1"></i> {dataItem.total_assign_staff}
                                    <i className="bi bi-calendar-range align-baseline ms-4 me-1"></i> {dataItem.start_date + "-" + dataItem.end_date}
                                  </p>
                                  <span className="text-muted fw-normal text-wrap d-block mt-3">{dataItem.description}</span>
                                </span>
                                <Link to="#" onClick={() => handleArchiveShiftTemplate(dataItem.id)} className="btn btn-primary btn-icon btn-sm float-end"><i className="bi bi-trash-fill"></i></Link>
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        ))}
                      </Row>
                    </SimpleBar>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="hstack gap-2 justify-content-center">
                      <Button variant="light" onClick={() => setOpenLoadTemplateModal(false)}>Cancel</Button>
                      <Button variant="danger" onClick={() => submitShiftTemplateFunc()} disabled={savingTemplateShifts}>
                        {savingTemplateShifts ?
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          ><span className="visually-hidden">Submitting...</span></Spinner> :
                          <><i className="ri-upload-2-fill"></i> Submit</>
                        }
                      </Button>
                    </div>
                  </Modal.Footer>
                </>
                :
                <Modal.Body className="p-4 m-4">
                  <Row>
                    <Col lg={12}>
                      <p className="text-center text-muted">No templates available.</p>
                    </Col>
                  </Row>
                </Modal.Body>
          }
        </Modal>
        <Modal
          show={publishModal}
          onHide={() => {
            setPublishModal(false);
          }}
          size="xl"
          centered
        >
          <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
            <h4>{publishModalForFinished?'Complete Shifts':'Publish'} For {selectedClient?.label}</h4>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="mt-4">
              <Row>
                <Col lg={4}>
                  <Row>
                    <Col className="align-self-center">
                      <h5>Select Days</h5>
                    </Col>
                    <Col className="align-self-end mb-1">
                      <div className="form-check" style={{ float: 'right' }}>
                        <input className="form-check-input me-2" type="checkbox" checked={isDateAllChecked} onChange={handleDateCheckAll} />
                        Select All
                      </div>
                    </Col>
                  </Row>
                  <ListGroup>
                    {publishData.dates.map((dateItem: any, index) => (
                      <ListGroup.Item key={index}>
                        <input className="form-check-input me-2" type="checkbox" checked={checkedPublishDates.includes(dateItem.date)}
                          onChange={() => handleDateCheckboxChange(dateItem.date)} />
                        {dateItem.dayName}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col className="align-self-center">
                      <h5>Select Staff</h5>
                    </Col>
                    <Col className="align-self-end mb-1">
                      <div className="form-check" style={{ float: 'right' }}>
                        <input className="form-check-input me-2" type="checkbox" checked={isStaffAllChecked} onChange={handleStaffCheckAll} />
                        Select All
                      </div>
                    </Col>
                  </Row>
                  <SimpleBar style={{ maxHeight: "300px" }} data-simplebar-direction="right" data-simplebar-track="primary">
                    <ListGroup>
                      {publishData.staff_list.map((staff: any, index) => (
                        <ListGroup.Item key={index}>
                          <input className="form-check-input me-2" type="checkbox" checked={checkedPublishStaff.includes(staff.staff_id)}
                            onChange={() => handleStaffCheckboxChange(staff.staff_id)} />
                          {staff.staff_name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </SimpleBar>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col className="align-self-center">
                      <h5>Select Location</h5>
                    </Col>
                    <Col className="align-self-end mb-1">
                      <div className="form-check" style={{ float: 'right' }}>
                        <input className="form-check-input me-2" type="checkbox" checked={isLocationAllChecked} onChange={handleLocationCheckAll} />
                        Select All
                      </div>
                    </Col>
                  </Row>
                  <SimpleBar style={{ maxHeight: "300px" }} data-simplebar-direction="right" data-simplebar-track="primary">
                    <ListGroup>
                      {publishData.location_list.map((location: any, index) => (
                        <ListGroup.Item key={index}>
                          <input className="form-check-input me-2" type="checkbox" checked={checkedPublishLocation.includes(location.location_id)}
                            onChange={() => handleLocationCheckboxChange(location.location_id)} />
                          {location.name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </SimpleBar>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setPublishModal(false)}>Cancel</Button>
              {publishLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={handlePublishShifts}><i className="ri-upload-2-fill"></i> {publishModalForFinished?'Save':'Publish'}</Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={archiveModal}
          onHide={() => setArchiveModal(false)}
          size="sm"
          centered
        >

          <Modal.Body className="text-center p-4">
            <i className="ri-delete-bin-line text-danger display-5"></i>
            <div className="mt-4">
              <h4 className="mb-3">Are you sure?</h4>
              <p className="text-muted mb-4">You want to pemenantly delete this Shift.</p>
              <div className="hstack gap-2 justify-content-center">
                <Button variant="light" onClick={() => setArchiveModal(false)}>Close</Button>
                <Button variant="danger" onClick={() => {
                  if (archiveId !== undefined) {
                    archiveShift(archiveId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal_center}
          onHide={() => {
            tog_center();
          }}
          size="sm"
          centered
        >

          <Modal.Body className="text-center p-4">
            <i className="ri-delete-bin-line text-danger display-5"></i>
            <div className="mt-4">
              <h4 className="mb-3">Are you sure?</h4>
              <p className="text-muted mb-4">You want to delete this template.</p>
              <div className="hstack gap-2 justify-content-center">
                <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                {archiveLoader ? (
                  <Button
                    color="primary"
                    className="next-btn"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <Button variant="danger" onClick={() => {
                    if (archiveTemplateId !== undefined) {
                      archiveShiftTemplate(archiveTemplateId);
                    } else {
                      toast.error("No Archive ID provided.");
                    }
                  }}>Delete</Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal id="myModal" show={paste_alert_standard} onHide={() => { paste_standard(); }} >
          <Modal.Header className="modal-title fs-xl" id="myModalLabel">
            Discard Changes ?
          </Modal.Header>
          <Modal.Body>
            <p className="text-muted">You have unsaved changes. If you proceed, all your changes will be lost. Are you sure you want to discard them?</p>
          </Modal.Body>
          <div className="modal-footer">
            <Button variant="light" onClick={() => paste_standard()}>Close</Button>
            <Button
              color="primary"
              onClick={() => {
                paste_standard();
                callRemovePasteData();
              }}
            >
              Discard changes
            </Button>
          </div>
        </Modal>
      </React.Fragment >
    </>
  );
};

export default ScheduleNew;
