import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  Col,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tooltip,
  Form,
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import moment from 'moment';

interface Event {
  id: number;
  date: string;
  title: string;
  staff_name?: string;
  start: string;
  end: string;
  class_name: string;
  shift_notes?: string;
  status: number;
  status_name?: string;
  is_night_shift: boolean;
  hasConflict: boolean;
  created_by: string;
  is_petrol_site: boolean;
  location_id: number;
  location_name: string;
  copy_from?: number;
}

interface DropdownData {
  value: number;
  label: string;
}

interface ScheduleWeekTableProps {
  openCreateShift: (day: string, location: DropdownData) => void;
  openShiftDetail: (event: Event) => void;
  handleEventDrop: (event: Event, newDate: string, locationData: any) => void;
  handlePasteMode: (e: Boolean) => void;
  eventData: Array<any>;
  eventDates: Array<any>;
  selectedClient: DropdownData | null;
}

const EventDiv: React.FC<any> = ({ event, index, onDragStart, openShiftDetail, onCopy, pasteMode, onDeletePasterEvent }) => {
  return (
    <div
      className={`${event.class_name} child_event`}
      draggable={event.class_name=="finished"?false:true}
      onDragStart={(e) => onDragStart(e, event)}
      onClick={() => openShiftDetail(event)}
    >
      {event.class_name === "Unavailable" ? (
        <p className="event-title mb-0">{event.class_name}</p>
      ) : (
        <div className="schedule w-100">
          <div className="week-table p-0">
            <div className={event.class_name}>
              <p className="event-title mb-1">{event.title}</p>
              <div style={{display:'flex',gap:'8px',justifyContent:'center'}}>  
                {(!pasteMode) && (
                  <button className="btn btn-copy p-0" onClick={(e) => {
                    e.stopPropagation();
                    onCopy(event);
                  }}>
                    <i className="ri-file-copy-line" />
                  </button>
                )}
                {(event.isPaste) && (
                  <button className="btn btn-copy text-danger p-0" onClick={(e) => {
                    e.stopPropagation();
                    onDeletePasterEvent(event);
                  }}>
                    <i className="bx bx-trash" />
                  </button>
                )}
                <p className="event-title mb-1">{event.staff_name}</p>
              </div>
              {event.status_name === "CLOCKED IN" && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-top" style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem" }}>
                      <div className="shift-note-tooltip">
                        <p className="mb-0">Clocked In</p>
                      </div>
                    </Tooltip>
                  }
                >
                  <i className="ri-timer-flash-line clock_in"></i>
                </OverlayTrigger>
              )}
              {/* Add other status indicators here */}
              {event.hasConflict ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-top" style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem" }}>
                      <div className="shift-note-tooltip">
                        <p className="mb-0 text-primary">This Shift is Overlapping</p>
                      </div>
                    </Tooltip>
                  }
                >
                  <i className="ri-error-warning-line conflict_shift text-primary" onClick={(e) => {
                      e.stopPropagation(); // Prevent parent click events
                    }} />
                </OverlayTrigger>
              ) : (
                ""
              )}
              {event.shift_notes && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-top" style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem" }}>
                      <div className="shift-note-tooltip">
                        <p>Shift Note</p>
                        {event.shift_notes}
                      </div>
                    </Tooltip>
                  }
                >
                  <i className="ri-file-list-2-line notes" />
                </OverlayTrigger>
              )}
            </div>
          </div>
          {event.is_night_shift ? <i className="ri-moon-fill night_shift" /> : <i className="ri-sun-fill night_shift" />}
        </div>
      )}
    </div>
  );
};

// const ScheduleWeekTableNew: React.FC<ScheduleWeekTableProps> = (({
const ScheduleWeekTableNew = forwardRef<any, ScheduleWeekTableProps>(({
  openCreateShift,
  openShiftDetail,
  handleEventDrop,
  handlePasteMode,
  eventData,
  eventDates
}, ref) => {
  useImperativeHandle(ref, () => ({
    triggerRemovePasteData() {
      handlePasteMode(false);
      setPastedEvents([]);
      // Remove pasted events from data where `copy_from` key exists
      setData((prevData) => {
        return prevData.map((location) => {
          const updatedShiftList = Object.keys(location.shift_list).reduce((acc: any, dayKey) => {
            const filteredEvents = location.shift_list[dayKey].filter(
              (event: any) => !event.copy_from // Keep events that don't have `copy_from`
            );
            acc[dayKey] = filteredEvents;
            return acc;
          }, {});

          return {
            ...location,
            shift_list: updatedShiftList, // Update shift list with filtered events
          };
        });
      });

      // Clear the copied event and exit paste mode
      setCopiedEvent(null);
      setPasteMode(false);
      console.log("Paste mode canceled and copied events removed.");
    },
    triggerSavePasteData() {
      const formattedEvents = pastedEvents.map(event => ({
        copy_from: event.copy_from,
        day_value: event.dayValue,
        location_id: event.location_id,
      }));
      console.log(pastedEvents);
      return formattedEvents;

    }
  }));

  const [profileInfo, setProfileInfo] = useState<boolean>(false);
  const profileInfoShow = () => setProfileInfo(!profileInfo);

  const [finishedShift, setFinishedShift] = useState<boolean>(false);
  const finishedShiftShow = () => setFinishedShift(!finishedShift);

  const [publishedShift, setPublishedShift] = useState<boolean>(false);
  const publishedShiftShow = () => setPublishedShift(!publishedShift);

  const getInitials = (name: string | undefined) => {
    if (!name) return "";

    // Remove dashes from the name
    const cleanedName = name.replace(/-/g, "");

    return cleanedName
      ?.split(' ')
      ?.map(word => word.charAt(0))
      ?.join('')
      ?.toUpperCase()
      ?.slice(0, 3);
  };

  const [data, setData] = useState(eventData);

  useEffect(() => {
    setData(eventData);
  }, [eventData]);

  const handleDrop = async (event: Event, newDay: any, newLocation: any) => {
    // Prevent drop if event conditions are not met
    if (event.class_name === "finished" || event.class_name === "archived" || event.status !== 1 || event.status_name === "CLOCKED IN") {
      return;
    }

    let updatedData = [...data]; // Clone the current state

    updatedData = updatedData.map((location: any) => {
      // Find the original day and location by comparing event IDs
      const originalDay = eventDates.find((day: any) =>
        location.shift_list[day.value]?.some((e: Event) => e.id === event.id)
      );

      if (location.location_id === event.location_id) {
        // Remove the event from the original day if it's the same location
        const updatedShiftList = {
          ...location.shift_list,
          [originalDay?.value]: location.shift_list[originalDay?.value].filter(
            (e: Event) => e.id !== event.id
          ),
        };
        location.shift_list = updatedShiftList;
      }

      // If we are in the new location, add the event to the new day
      if (location.location_id === newLocation.location_id) {
        const updatedShiftList = {
          ...location.shift_list,
          [newDay.value]: [...(location.shift_list[newDay.value] || []), { ...event, location_id: newLocation.location_id }],
        };
        location.shift_list = updatedShiftList;
      }

      return location;
    });

    // Check if the event is in pastedEvents and update the pasted event's new location and day
    if (event.copy_from) {
      setPastedEvents((prevEvents) => {
        return prevEvents.map((pastedEvent) => {
          if (pastedEvent.id === event.id) {
            return {
              ...pastedEvent,
              dayValue: newDay.value,
              location_id: newLocation.location_id,
              start: `${moment(newDay.value, 'DD-MM-YYYY').format('YYYY-MM-DD')}${event.start.substring(10)}`
            };
          }
          return pastedEvent;
        });
      });
    }

    // Update state to trigger re-render
    setData(updatedData);

    // Call the async function to update the server after updating local state
    try {
      await handleEventDrop(event, newDay, newLocation);
    } catch (error) {
      console.error("Error updating event on drop:", error);
    }
  };




  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const [copiedEvent, setCopiedEvent] = useState<any>(null);
  const [pastedEvents, setPastedEvents] = useState<any[]>([]);
  const [pasteMode, setPasteMode] = useState(false);
  const [pastePosition, setPastePosition] = useState<{ locationId: string, dayValue: string } | null>(null);

  const handleCopy = (event: any) => {
    setPasteMode(true);
    handlePasteMode(true);
    setCopiedEvent(event);
  };
  const handleDeleteEvent = (event: any) => {
    // Remove the deleted event from pastedEvents
    setPastedEvents((prev) => prev.filter((e) => e.id !== event.id));
  
    // Revert the changes in the data state
    setData((prevData) =>
      prevData.map((location) => {
        if (location.location_id === event.location_id) {
          const updatedShiftList = { ...location.shift_list };
  
          // Remove the event from the shift list for the corresponding day
          for (const day in updatedShiftList) {
            updatedShiftList[day] = updatedShiftList[day].filter(
              (e: any) => e.id !== event.id
            );
          }
  
          return {
            ...location,
            shift_list: updatedShiftList
          };
        }
        return location;
      })
    );
  
    console.log("Deleted event details:", event); // Log the deleted event details
  };

  const handlePaste = (locationData: any, day: any) => {
    if (copiedEvent) {
      const formattedDay = moment(day.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
      const newStartTime = `${formattedDay}${copiedEvent.start.substring(10)}`;
      const newEndTime = `${formattedDay}${copiedEvent.end.substring(10)}`;

      const staffId =
      copiedEvent.location_id === locationData.location_id ||
      locationData.is_petrol_site === 1 ||
      locationData.assigned_staff.includes(copiedEvent.staff_id)
        ? copiedEvent.staff_id
        : 0;

      const newEvent = {
        ...copiedEvent,
        id: Date.now(),
        copy_from: copiedEvent.id,
        start: newStartTime,
        end: newEndTime,
        location_id: locationData.location_id,
        location_name: locationData.location_name,
        dayValue: day.value,
        staff_id: staffId,
        staff_name: staffId ? copiedEvent.staff_name : "",
        status_name: "unpublished",
        class_name: staffId ? "unpublished" : "available",
        hasConflict: false,
        isPaste : true,
      };

      setPastedEvents((prev) => [...prev, newEvent]);

      setData((prevData) =>
        prevData.map((location) => {
          if (location.location_id === locationData.location_id) {
            const updatedShiftList = {
              ...location.shift_list,
              [day.value]: [...(location.shift_list[day.value] || []), newEvent]
            };
            return {
              ...location,
              shift_list: updatedShiftList
            };
          }
          return location;
        })
      );
    }
  };
  return (
    <>
      <SimpleBar style={{ maxHeight: "67vh" }}>
        <table className="table table-bordered table-nowrap">
          <thead style={{ position: "sticky", top: "-2px", zIndex: 2, backgroundColor: "white" }}>
            <tr>
              <th scope="col" className="data-head-col">
                <div>Locations</div>
              </th>
              {eventDates.map((dateFormat: any, index: number) => (
                <th key={index} scope="col" className="data-head-col">
                  {dateFormat.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 && data.map((locationData) => (
              <tr key={locationData?.location_id} className="week-table">
                <td onClick={profileInfoShow} className="py-0 align-middle" style={{ whiteSpace: "pre-wrap" }}>
                  <Row className="data-name my-0">
                    <Col lg={2}>
                      <span className="name-img">
                        <i className="ri-map-pin-line"></i>
                      </span>
                    </Col>
                    <Col lg={10}>
                      <p className="name-title">{locationData?.location_name}</p>
                    </Col>
                  </Row>
                </td>
                {eventDates.map((day, index) => (
                  <td
                    className="events_div"
                    onDragOver={onDragOver}
                    onDrop={(e) => {
                      e.preventDefault();
                      if(pasteMode){
                        return;
                      }
                      const event = JSON.parse(e.dataTransfer.getData('event'));
                      handleDrop(event, day, locationData); // Pass the location data as well
                    }}
                    key={index}
                  >
                    {locationData.shift_list[day.value] && locationData.shift_list[day.value].map((event: any, index: number) => (
                      <EventDiv
                        key={index}
                        event={event}
                        index={index}
                        onDragStart={(e: any, event: any) => {
                          if(pasteMode){
                            return;
                          }
                          e.dataTransfer.setData('event', JSON.stringify(event));
                        }}
                        openShiftDetail={openShiftDetail}
                        onCopy={handleCopy}
                        pasteMode={pasteMode}
                        onDeletePasterEvent={handleDeleteEvent}
                      />
                    ))}
                    <Row>
                      <Col md="12" className="text-center">
                        <button className="btn btn-ghost-primary btn-sm addShiftBtn"
                          onClick={() => openCreateShift(day.value, { 'label': locationData?.location_name, 'value': locationData?.location_id })}
                        >
                          <i className="ri-add-circle-line" style={{ fontSize: "0.8rem" }}></i>
                        </button><br />
                        {/* Paste Button */}
                        {(copiedEvent) && (
                          <button
                            className="btn btn-info" style={{ fontSize: "0.5rem", padding: "0.2rem" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePaste(locationData, day);
                            }}
                          >
                            Paste
                          </button>
                        )}
                      </Col>
                    </Row>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </SimpleBar>
      <Offcanvas
        className="profile-view-canvas"
        show={profileInfo}
        placement="end"
        onHide={profileInfoShow}
      >
        <Offcanvas.Header
          id="createShift"
          className="border-bottom"
          closeButton
        >
          <Offcanvas.Title id="createShiftLabel">Profile</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg={12} className="profile-details">
              <div className="name-logo">{getInitials("Adams Smith")}</div>
              <h4 className="name">Adams Smith</h4>
              <p className="profession">Contract Worker</p>
              <p className="role">Doctor</p>
            </Col>
            <Col lg={12} className="other-details">
              <Row>
                <Col lg={6} className="detail-col">
                  <p className="title">Contact Number</p>
                  <p className="details">+61 65214 63214</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Email</p>
                  <p className="details">adams@gmail.com</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Weekly Working Hours</p>
                  <p className="details">40 Hr</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Working Locations</p>
                  <p className="details">XYZ Hospital, ASD Hospital +2</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Emergency Contact Person Name</p>
                  <p className="details">Mia Smith</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Emergency Contact Person Number</p>
                  <p className="details">+61 45212 36542</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        className="profile-view-canvas"
        show={finishedShift}
        placement="end"
        onHide={finishedShiftShow}
      >
        <Offcanvas.Header
          id="createShift"
          className="border-bottom"
          closeButton
        >
          <Offcanvas.Title id="createShiftLabel">
            <i
              className="bx bx-calendar align-middle me-1"
              style={{ color: "#999" }}
            ></i>{" "}
            Mon 4. Dec . 2023
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg={2} className="shift-status">
              <div className="name-logo">{getInitials("Adams Smith")}</div>
            </Col>
            <Col lg={10} className="shift-status">
              <h4 className="name">Adams Smith</h4>
              <p className="role">
                Doctor <span>16 Hrs</span>
              </p>
            </Col>
            <Col lg={12} className="shift-status">
              <div className="status">Finished Shifts</div>
            </Col>
            <Col lg={12} className="other-details">
              <Row>
                <Col lg={6} className="detail-col">
                  <p className="title">Shift Start Time</p>
                  <p className="details">09:00 am</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Shift Finish Time</p>
                  <p className="details">05:00 pm</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Unpaid Break (in min)</p>
                  <p className="details">30 Minutes</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Paid Break (in min)</p>
                  <p className="details">30 Minutes</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Department</p>
                  <p className="details">Hospital</p>
                </Col>
                <Col lg={6} className="detail-col">
                  <p className="title">Shifts Option</p>
                  <p className="details">Publish shift</p>
                </Col>
                <Col lg={12} className="detail-col">
                  <p className="title">Shifts Note</p>
                  <p className="details">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        className="filter-canvas shift-canvas"
        show={publishedShift}
        placement="end"
        onHide={publishedShiftShow}
      >
        <Offcanvas.Header
          id="createShift"
          className="border-bottom shift-canvas"
          closeButton
        >
          <Offcanvas.Title id="createShiftLabel">
            Published Shift
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg={12} className="profile-details">
              <span>name</span>
              <h4>Adams Smith</h4>
              <p>Contract Worker</p>
              <p>Doctor</p>
            </Col>
            <Col lg={12} className="other-details">
              <Row>
                <Col lg={6}>
                  <p className="title">Contact Number</p>
                  <p className="details">+61 65214 63214</p>
                </Col>
                <Col lg={6}>
                  <p className="title">Email</p>
                  <p className="details">adams@gmail.com</p>
                </Col>
                <Col lg={6}>
                  <p className="title">Weekly Working Hours</p>
                  <p className="details">40 Hr</p>
                </Col>
                <Col lg={6}>
                  <p className="title">Working Locations</p>
                  <p className="details">XYZ Hospital, ASD Hospital +2</p>
                </Col>
                <Col lg={6}>
                  <p className="title">Emergency Contact Person Name</p>
                  <p className="details">Mia Smith</p>
                </Col>
                <Col lg={6}>
                  <p className="title">Emergency Contact Person Number</p>
                  <p className="details">+61 45212 36542</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
});

export default ScheduleWeekTableNew;
