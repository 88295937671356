//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_LOGIN = "login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";
export const GET_USER_PROFILE = "/get_profile_detail";
export const UPDATE_USER_PROFILE = "/update_profile_detail";
export const FORGOT_USER_PWD = "/send_reset_link";
export const CHANGE_PASSWORD = "/change_password";
export const CHECK_RESET_TOKEN = "/check_reset_token";
export const RESET_PASSWORD = "/reset_password";
export const LOGOUT = "logout";

//COMPANY DETAILS
export const GET_COMPANY = "settings/getCompanyDetail";
export const UPDATE_COMPANY = "settings/updateCompanyDetails";

//STATE MASTER
export const GET_STATE_MASTER = "state/list";

//COMPLIANCE MASTER
export const GET_COMPLIANCE_MASTER = "compliance/list";
export const ADD_COMPLIANCE_MASTER = "compliance/add";
export const ARCHIVE_COMPLIANCE_MASTER = "compliance/archive";
export const GETBY_ID_COMPLIANCE_MASTER = "compliance/getById";
export const UPDATE_COMPLIANCE_MASTER = "compliance/update";

//HOLIDAY MASTER
export const GET_HOLIDAY_MASTER = "holiday/list";
export const ADD_HOLIDAY_MASTER = "holiday/add";
export const ARCHIVE_HOLIDAY_MASTER = "holiday/archiveById";
export const GETBY_ID_HOLIDAY_MASTER = "holiday/getById";
export const UPDATE_HOLIDAY_MASTER = "holiday/update";

//SUBCONTRACTOR MASTER
export const GET_SUBCONTRACTOR_MASTER = "subcontractor/list";
export const ADD_SUBCONTRACTOR_MASTER = "subcontractor/add";
export const ARCHIVE_SUBCONTRACTOR_MASTER = "subcontractor/archiveById";
export const GETBY_ID_SUBCONTRACTOR_MASTER = "subcontractor/getById";
export const UPDATE_SUBCONTRACTOR_MASTER = "subcontractor/update";

//CALENDER
export const GET_EVENTS = "/events";
export const GET_CATEGORIES = "/categories";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";

//STAFF MODULE
export const GET_STAFF = "staff/list";
export const ADD_STAFF = "staff/invite";
export const GETBY_ID_STAFF = "staff/getById";
export const ACCESS_LEVEL = "staff/roleList";
export const EMP_TYPE = "staff/empleyeeTypeList";
export const SUBCONTRACTOR_LIST_STAFF = "staff/subcontractorList";
export const BULK_STAFF_ADD = "staff/bulkInvite";
export const LOCATION_LIST = "staff/locationList";
export const ARCHIVE_STAFF = "staff/archiveById";
export const RESTORE_STAFF = "staff/restoreById";
export const LEAVE_LIST = "staff/leaveTypeList";
export const UPDATE_STAFF = "staff/update";
export const EXPORT_STAFF = "staff/export";
export const CHECK_PASSWORD_TOCKEN = "staff/verifyPassowrdToken";
export const UPDATE_PASSWORD = "staff/setPassword";
export const VERIFICATION_TOCKEN = "staff/verifyOnboardToken";
export const ONBOARDING_PROCESS_STAFF = "staff/onboarding";
export const STAFF_LOCATION_LIST = "staff/locationList";
export const PAY_GROUP_LIST = "dropdown/payGroupList";
export const PAY_GROUP_BY_ID = "staff/getPayGroupById";
export const SEND_ONBOARDING_LINK = "staff/sendOnboardingLink"

//STAFF DOCUMENT
export const STAFF_DOCUMENT = "staff_document/list";
export const ADD_STAFF_DOCUMENT = "staff_document/add";
export const GET_BY_STAFF_DOCUMENT = "staff_document/getById";
export const UPDATE_STAFF_DOCUMENT = "staff_document/update";
export const DELETE_STAFF_DOCUMENT = "staff_document/deleteById";
export const DROPDOWN_COMPLIANCE = "dropdown/compliance";

//COMPANY DOCUMENT MASTER
export const GET_COMPANY_DOCUMENT_MASTER = "company_doc/list";
export const ADD_COMPANY_DOCUMENT_MASTER = "company_doc/add";
export const ARCHIVE_COMPANY_DOCUMENT_MASTER = "company_doc/archiveById";
export const GETBY_ID_COMPANY_DOCUMENT_MASTER = "company_doc/getById";
export const UPDATE_COMPANY_DOCUMENT_MASTER = "company_doc/update";

//LEAVE MASTER
export const GET_LEAVE_MASTER = "leave_category/list";
export const ADD_LEAVE_MASTER = "leave_category/add";
export const ARCHIVE_LEAVE_MASTER = "leave_category/archive";
export const GETBY_ID_LEAVE_MASTER = "leave_category/getById";
export const UPDATE_LEAVE_MASTER = "leave_category/update";

//INVOICE MASTER
export const GET_INVOICE_MASTER = "invoice_template/list";
export const ADD_INVOICE_MASTER = "invoice_template/add";
export const ARCHIVE_INVOICE_MASTER = "invoice_template/archive";
export const GETBY_ID_INVOICE_MASTER = "invoice_template/getById";
export const UPDATE_INVOICE_MASTER = "invoice_template/update";

//PAYMENT TERM MASTER
export const GET_PAYMENT_TERM_MASTER = "payment_term/list";
export const ADD_PAYMENT_TERM_MASTER = "payment_term/add";
export const ARCHIVE_PAYMENT_TERM_MASTER = "payment_term/archive";
export const GETBY_ID_PAYMENT_TERM_MASTER = "payment_term/getById";
export const UPDATE_PAYMENT_TERM_MASTER = "payment_term/update";

//INCIDENT TEMPLATE MASTER
export const GET_INCIDENT_TEMPLATE_MASTER = "incident_template/list";
export const ADD_INCIDENT_TEMPLATE_MASTER = "incident_template/add";
export const ARCHIVE_INCIDENT_TEMPLATE_MASTER = "incident_template/archive";
export const GETBY_ID_INCIDENT_TEMPLATE_MASTER = "incident_template/getById";
export const UPDATE_INCIDENT_TEMPLATE_MASTER = "incident_template/update";
export const GET_INCIDENT_TEMPLATE_DROPDOWN_MASTER =
  "incident_template/dropdown";

//INCIDENT REPORT
export const ADD_INDICENT_REPORT = "incident_report/add";
export const GET_INDICENT_REPORT = "incident_report/list";
export const ARCHIVE_INDICENT_REPORT = "incident_report/archive";
export const GETBY_ID_INDICENT_REPORT = "incident_report/getById";
export const UPDATE_INDICENT_REPORT = "incident_report/update";
export const GET_DROPDOWN_CLIENT_INDICENT_REPORT =
  "incident_report/clientDropdown";
export const GET_LOCATION_DROPDOWN_INDICENT_REPORT =
  "incident_report/getLocationsFromClient";

//SUBCONTRACTOR MODULE
export const GET_SUBCONTRACTOR = "subcontractor/list";
export const ADD_SUBCONTRACTOR = "subcontractor/add";
export const GETBY_ID_SUBCONTRACTOR = "subcontractor/getById";
export const UPDATE_SUBCONTRACTOR = "subcontractor/update";
export const ADD_BULK_SUBCONTRACTOR = "subcontractor/addBulk";
export const ARCHIVE_SUBCONTRACTOR = "subcontractor/archive";
export const RESTORE_SUBCONTRACTOR = "subcontractor/restore";
export const EXPORT_SUBCONTRACTOR = "subcontractor/export";
export const SUBCONTRACTOR_SEND_EMAIL = "subcontractor/sendInviteEmail"

//SUBCONTRACTOR COMPLIANCE
export const SUB_ADD_SUBCONTRACTOR = "sub_compliance/add";
export const SUB_GET_SUBCONTRACTOR = "sub_compliance/list";
export const SUB_ARCHIVE_SUBCONTRACTOR = "sub_compliance/archive";
export const SUB_GETBY_ID_SUBCONTRACTOR = "sub_compliance/getById";
export const SUB_UPDATE_SUBCONTRACTOR = "sub_compliance/update";

//CLIENT MODULE
export const GET_CLIENT = "client/list";
export const ADD_CLIENT = "client/add";
export const GET_BY_CLIENT = "client/getById";
export const UPDATE_CLIENT = "client/update";
export const ARCHIVE_CLIENT = "client/archive";
export const EXPORT_CLIENT = "client/export";
export const RESTORE_CLIENT = "client/restore";

//CLIENT DOCUMENT
export const ADD_CLIENT_DOCUMENT = "client_document/add";
export const GET_CLIENT_DOCUMENT = "client_document/list";
export const GET_BY_CLIENT_DOCUMENT = "client_document/getById";
export const UPDATE_CLIENT_DOCUMENT = "client_document/update";
export const ARCHIVE_CLIENT_DOCUMENT = "client_document/deleteById";

//LOCATION MODULE
export const GET_LOCATION = "location/list";
export const LOCATION_CLIENT_FILTER = "location/clientList";
export const Location_Incident_LIst = "location/incidentList";
export const ADD_LOCATION = "location/add";
export const GET_BY_ID_LOCATION = "location/getById";
export const ARCHIVE_LOCATION = "location/archive";
export const UPDATE_LOCATION = "location/update";
export const RESTORE_LOCATION = "location/restore";
export const LOCATION_SUBCONTRACTOR_FILTER = "location/subcontractorList";
export const EXPORT_LOCATION = "location/export";
export const DOWNLOAD_CHECKPOINT_QR = "location/checkpoint/download_qr";
export const DROPDOWN_BILLING_GROUP = "dropdown/billingGroupList";
export const GET_BILLING_GROUP_BY_ID = "location/getBillingGroupById";

//TIME OFF MODULE
export const GET_TIME_OFF = "time_off/list";
export const ADD_TIME_OFF = "time_off/add";
export const UPDATE_TIME_OFF = "time_off/update";
export const ARCHIVE_TIME_OFF = "time_off/archive";
export const EMPLOYEE_TIME_OFF = "time_off/employeeDropdown";
export const LEAVE_TIME_OFF = "time_off/LeaveTypeDropdown";
export const LEAVE_BALANCED_TIME_OFF = "time_off/leaveBalance";
export const GET_BY_ID_TIME_OFF = "time_off/getById";
export const RESTORE_TIME_OFF = "time_off/restore";
export const APPROVE_BY_ID = "time_off/approveById";

//SCHEDULE MODULE
export const SCHEDULE_CLIENT_LIST = "scheduling/clientList";
export const SCHEDULE_LOCATION_LIST = "scheduling/locationList";
export const SCHEDULE_STAFF_LIST = "scheduling/staffList";
export const SCHEDULE_CREATE = "scheduling/createShift";
export const SCHEDULE_LIST = "scheduling/listShifts";
export const ALL_LOCATION_SCHEDULE_LIST = "scheduling/listAllLocationsShifts";
export const SCHEDULE_UPDATE_SHIFT = "scheduling/updateShift";
export const SCHEDULE_SHIFT_DETAILS = "scheduling/getDetailsById";
export const SCHEDULE_EXPORT = "scheduling/export";
export const SCHEDULE_PUBLISH_DATA = "scheduling/getPublishData";
export const SCHEDULE_PUBLISH_SHIFT = "scheduling/publishShifts";
export const SCHEDULE_ARCHIVE_SHIFT = "scheduling/archiveShift";
export const SHIFT_HISTORY = "scheduling/getShiftHistory";
export const PETROL_LOCATION_INFO = "scheduling/getPetrolLocationById";
export const REJECT_SHIFT = "scheduling/rejectShift";
export const CHECK_POINT_LOG_DETAILS = "scheduling/shiftCheckpointLogDetails"
export const EXPORT_CHECKPOINT_LOG_DETAILS = "scheduling/shiftCheckpointExport"
export const SAVE_SHIFT_TEMPLATE = "shiftTemplate/add"
export const LOAD_SHIFT_TEMPLATE = "shiftTemplate/list"
export const SUBMIT_SHIFT_TEMPLATE = "shiftTemplate/submit"
export const SHIFT_TEMPLATE_ARCHIVE = "shiftTemplate/archive"
export const SCHEDULE_PUBLISH_DATA_WITH_LOCATION = "scheduling/getPublishDataWithLocation";
export const SCHEDULE_PUBLISH_SHIFT_WITH_LOCATION = "scheduling/publishShiftsWithLocation";
export const SCHEDULE_SUMMARY = "scheduling/allLocationsShiftsSummary";
export const SCHEDULE_PASTE_SHIFT = "scheduling/pasteShift"
export const SCHEDULE_PUBLISHED_SHIFT_LIST = "scheduling/publishedShiftList";
export const SCHEDULE_FINISHED_BULK_SHIFT = "scheduling/finishedBulkShift";


//TIMESHEET MODULE
export const GET_TIMESHEET = "timesheet/list";
export const CLIENT_DROPDOWN_TIMESHEET = "timesheet/clientList";
export const LOCATION_DROPDOWN_TIMESHEET = "timesheet/locationList";
export const STAFF_DROPDOWN_TIMESHEET = "timesheet/staffList";
export const ADD_TIMESHEET = "timesheet/add";
export const GET_BREAK_LIST = "timesheet/getBreakTypeList";
export const GET_BY_TIMESHEET = "timesheet/getDetailsById";
export const UPDATE_TIMESHEET = "timesheet/update";
export const APPROVE_BY_ID_TIMESHEET = "timesheet/approveByIds";
export const EXPORT_TIMESHEET = "timesheet/export";
export const GET_HISTORY = "timesheet/getHistory";

//INVOICE MODULE
export const GET_INVOICE = "invoice/list";
export const ARCHIVE_INVOICE = "invoice/add";
export const CLIENT_DROPDOWN_INVOICE = "invoice/clientList";
export const INVOICE_TIMESHEET = "invoice/timsheetDetails";
export const INVOICE_TEMPLATE = "invoice/invoiceTemplateList";
export const INVOICE_TERM = "invoice/paymentTermList";
export const ADD_INVOICE = "invoice/add";
export const GET_BY_INVOICE = "invoice/getById";
export const UPDATE_BY_INVOICE = "invoice/update";
export const APPROVE_BY_ID_INVOICE = "invoice/invoicePaidById";
export const ARCHIVE_ID_INVOICE = "invoice/archiveById";
export const DOWNLOAD_INVOICE = "invoice/downloadInvoice";
export const CLIENT_LOCATION_DROPDOWN = "invoice/clientLocationList";
export const EXPORT_INVOICE = "invoice/export";
export const GET_BY_INOVICE_PAYMENT = "invoice/invoicePaymentGetById";
export const ADD_INVOICE_PAYMENT = "invoice/invoicePaymentadd";
export const REMOVE_INVOICE_PAYMENT = "invoice/invoicePaymentArchiveById"
export const EXPORT_INVOICE_PAYMENT = "invoice/exportInvoicePaymentLog"

//PAY SCHEDULE MASTER//
export const PAY_SCHUDULE_CALENDER_LIST = "pay_calendar/payCalendarTypeList";
export const GET_PAY_SCHEDULE_LIST = "pay_calendar/list";
export const ADD_PAY_SCHEDULE_LIST = "pay_calendar/add";
export const GET_BY_SCHEDULE_LIST = "pay_calendar/getById";
export const UPDATE_PAY_SCHEDULE_LIST = "pay_calendar/update";
export const REMOVE_PAY_SCHEDULE_LIST = "pay_calendar/archiveById";

//PAY CATEGORIES MASTER//
export const GET_PAY_CATEGORIES_LIST = "pay_categories/list";
export const ADD_PAY_CATEGORIES_LIST = "pay_categories/add";
export const GET_BY_CATEGORIES_LIST = "pay_categories/getById";
export const UPDATE_PAY_CATEGORIES_LIST = "pay_categories/update";
export const REMOVE_PAY_CATEGORIES_LIST = "pay_categories/archiveById";

//PAY GROUP MASTER//
export const PAY_CALENDER_CATEGORIES_LIST = "pay_group/payCategoriesList";
export const GET_PAY_GROUP_LIST = "pay_group/list";
export const ADD_PAY_GROUP_LIST = "pay_group/add";
export const GET_BY_GROUP_LIST = "pay_group/getById";
export const UPDATE_PAY_GROUP_LIST = "pay_group/update";
export const REMOVE_PAY_GROUP_LIST = "pay_group/archiveById";

//COMMON DROPDOWN
export const DROPDOWN_SUBCONTRACTOR = "dropdown/subcontractor";
export const DROPDOWN_CLIENT = "dropdown/client";
export const DROPDOWN_STAFF = "dropdown/staff";
export const DROPDOWN_LOCATION = "dropdown/clientLocationList";

//PATROL MODULE
export const PATROL_CLIENT_DRODOWN = "petrol/clientDropdown";
export const PATROL_LOCATION_DROPDOWN = "petrol/getLocationsByClient";
export const ADD_PATROL_LOCATION = "petrol/add";
export const GET_PATROL_LOCATION = "petrol/list";
export const GET_BY_PATROL_LOCATION = "petrol/getById";
export const ARCHIVE_PATROL_LOCATION = "petrol/archiveById";
export const UPDATE_PATROL_LOCATION = "petrol/update";
export const EXPORT_PATROL_LOCATION = "petrol/export";

// DASHBOARD
export const GET_DASHBOARD_DETAILS = "dashboard/dashboardList";
export const GET_CLOCK_DASHBOARD_DETAILS = "dashboard/clockInOutImage"

// PAYROLL MODULE
export const PAYROLL_DETAILS = "payroll/list";
export const PAY_SCHEDULE_DROPDOWN = "dropdown/payScheduleList";
export const ADD_PAYROLL_DETAILS = "payroll/generatePayRun";
export const GET_BY_PAYROLL_DETAILS = "payroll/getById";
export const UPDATE_PAYROLL_DETAILS = "payroll/update";
export const ARCHIVE_PAYROLL_DETAILS = "payroll/archiveById";
export const GET_PAYROLL_BY_STAFF = "payroll/getStaffPayRunById";
export const UPDATE_PAYRUN_DETAILS = "payroll/updateStaffPayRunData";
export const ARCHIVE_PAYRUN_DETAILS = "payroll/archiveStaffPayRateDataById";
export const ARCHIVE_STAFF_PAYRATE_DETAILS = "payroll/archiveStaffPayRateById";
export const PAY_RUN_APPROVE_BY_ID = "payroll/staffPayRunApproveById";
export const PENDING_TIMESHEET = "payroll/pendingPayrollTimesheet";
export const PENDING_PAYROLL_BY_ID = "payroll/getPendingPayrollByStaffId";
export const REPROCESS = "payroll/reprocess";
export const PAY_RUN_EXPORT = "payroll/export";

//BILLING CATEGORIES MASTER//
export const GET_BILLING_CATEGORIES_LIST = "billing_category/list";
export const ADD_BILLING_CATEGORIES_LIST = "billing_category/add";
export const GET_BY_BILLING_CATEGORIES_LIST = "billing_category/getById";
export const UPDATE_BILLING_CATEGORIES_LIST = "billing_category/update";
export const REMOVE_BILLING_CATEGORIES_LIST = "billing_category/archiveById";

//BILLING GROUP MASTER//
export const BILLING_CALENDER_CATEGORIES_LIST =
  "billing_group/billingCategoriesList";
export const GET_BILLING_GROUP_LIST = "billing_group/list";
export const ADD_BILLING_GROUP_LIST = "billing_group/add";
export const GET_BY_BILLING_GROUP_LIST = "billing_group/getById";
export const UPDATE_BILLING_GROUP_LIST = "billing_group/update";
export const REMOVE_BILLING_GROUP_LIST = "billing_group/archiveById";

// NOTIFICATION //
export const NOTIFICATION_LIST = "notification/list";
export const REMOVE_NOTIFICATION = "notification/archivedAll";
