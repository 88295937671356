import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
//import images
import avatar1 from "assets/images/users/32/avatar-1.jpg";
import { createSelector } from 'reselect';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { userLogout } from 'helpers/saibackend_helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useProfile } from "Common/Hooks/UserHooks";

const ProfileDropdown = () => {
    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);
    // const [userName, setUserName] = useState<any>("Admin");

    // const selectUser = createSelector(
    //     (state: any) => state.Profile,
    //     (user) => user.user
    // );

    // const user = useSelector(selectUser);

    // useEffect(() => {
    //     if (localStorage.getItem("authUser")) {
    //         const authUser: any = localStorage.getItem("authUser")
    //         const obj = JSON.parse(authUser);
    //         setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user?.username ? user?.username : "Admin" : "Admin" || "Admin" :
    //             process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.email && obj.email : "Admin"
    //         );
    //     }
    // }, [userName, user]);

    const getInitials = (name: string) => {
        if(!name) return "";
        const nameArray = name.trim().split(" ");
        if (nameArray.length === 1) return nameArray[0][0].toUpperCase();
        return nameArray.map(part => part[0].toUpperCase()).join("");
    };

    const obj = JSON.parse(localStorage.getItem("authUser") || "{}");
    const userId = obj?.data?.user_detail?.name
    const initials = getInitials(userId);


    const handleLogout = async () => {
        try {
            const response = await userLogout();
            if (response.status === 0) {
                toast.error(response.message);
                return;
            } else {
                localStorage.removeItem("authUser");
                toast.success(response.message);
                setTimeout(() => {
                    window.location.href = process.env.PUBLIC_URL + "/logout";
                }, 1000);
            }
        } catch (error) {
            console.error("Logout API failed: ", error);
            localStorage.removeItem("authUser");
            toast.success("You have been sucessfully logged out!.");
            setTimeout(() => {
                window.location.href = process.env.PUBLIC_URL + "/logout";
            }, 1000);
        }
    };
    

    return (
        <React.Fragment>
            <Dropdown className="ms-sm-3 header-item topbar-user">
                <Dropdown.Toggle type="button" className="btn bg-transparent border-0 arrow-none" id="page-header-user-dropdown">
                    <span className="d-flex align-items-center">
                        <div className="avatar-xs">
                            <div className="avatar-title rounded bg-primary-subtle text-primary">
                                {initials}
                            </div>
                        </div>
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">Hello, {userId}</span>
                            {/* <span className="d-none d-xl-block ms-1 fs-sm text-muted user-name-sub-text">Founder</span> */}
                        </span>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userId}!</h6>
                    <Dropdown.Item href={process.env.PUBLIC_URL + "/user-profile"}><i className="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Profile</span></Dropdown.Item>
                    <Dropdown.Item href="/auth-pass-change"><i className="ri-lock-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Change Password</span></Dropdown.Item>
                    {canAccess &&
                        <Dropdown.Item href="/company-setting"><i className="ri-settings-2-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Company Settings</span></Dropdown.Item>
                    }
                    {/* <Dropdown.Item href="#"><i className="mdi mdi-calendar-check-outline text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Taskboard</span></Dropdown.Item> */}
                    {/* <Dropdown.Item href="#"><i className="mdi mdi-lifebuoy text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Help</span></Dropdown.Item> */}
                    <div className="dropdown-divider"></div>
                    {/* <Dropdown.Item href="#"><i className="mdi mdi-wallet text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Balance : <b>$8451.36</b></span></Dropdown.Item> */}
                    {/* <Dropdown.Item href="#"><span className="badge bg-soft-success text-success mt-1 float-end">New</span><i className="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Settings</span></Dropdown.Item> */}
                    {/* <Dropdown.Item href="#"><i className="mdi mdi-lock text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Lock screen</span></Dropdown.Item> */}
                    <Formik
                        initialValues={{}}
                        onSubmit={handleLogout}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Dropdown.Item as="button" type="submit" disabled={isSubmitting}>
                                    <i className="mdi mdi-logout text-muted fs-lg align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span>
                                </Dropdown.Item>
                            </Form>
                        )}
                    </Formik>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
}

export default ProfileDropdown;