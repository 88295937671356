import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "Common/TableContainer";
import {
    Badge,
    Offcanvas,
    Button,
    Row,
    Col,
    Card,
    Table,
    Modal
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import LocationMap from "./LocationMap";
import { getByLocationDetail, getCheckpointQrImage, getByStaffDetail, getStaffDocumentDetail } from "helpers/saibackend_helper";

import avatar1 from "assets/images/users/48/avatar-1.jpg";
import avatar2 from "assets/images/users/48/avatar-2.jpg";
import avatar4 from "assets/images/users/48/avatar-4.jpg";
import avatar5 from "assets/images/users/48/avatar-5.jpg";
import avatar6 from "assets/images/users/48/avatar-6.jpg";
import avatar7 from "assets/images/users/48/avatar-7.jpg";
import avatar8 from "assets/images/users/48/avatar-8.jpg";

interface LocationData {
    id: number;
    name: string;
    address: string;
    city: string;
    client_name: string;
    subcontractor_name: string;
    state: string;
    PostalCode: string;
    country: string;
    is_billable: number;
    client_id: number;
    short_notes: string;
    notes: string;
    longitude: string;
    latitude: string;
    start_time: string;
    end_time: string;
    working_days: [];
    checkpoints: [
        {
            latitude: string,
            longitude: string,
            tag: string,
            qr_id: number,
            name: string,
            address: string,
            mobile_check_in_max_distance: number
        }
    ];
    contacts: [
        {
            contact_person: string,
            contact_phone: string,
            contact_email: string,
            contact_type: string,
        }
    ];
    documents: [
        {
            document_name: string,
            document_path: string,
            is_viewable_staff: number,
            require_sign: number
        }
    ];
    pay_templates: [];
    staff: [
        {
            staff_id: number,
            staff_name: string,
            sub_abb: any
        }
    ];
    incident_report_template: [
        {
            incident_report_template_id: number,
            name: string
        }
    ];
}

const LocationTable = ({
    data,
    currentPage,
    totalPages,
    onPageChange,
    handleSortChange,
    handleArchiveLocation,
    handleRestoreLocation,
    canAccess
}: {
    data: any;
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    handleSortChange: (column: string, direction: string) => void,
    handleArchiveLocation: (id: number) => Promise<void>,
    handleRestoreLocation: (id: number) => Promise<void>,
    canAccess: boolean
}) => {
    const [isBottom, setIsBottom] = useState(false);
    const [clickedItemId, setClickedItemId] = useState<string | null>(null);

    const toggleBottomCanvas = (id: string | null) => {
        setIsBottom(!isBottom);
        if (id) {
            setClickedItemId(id);
            localStorage.setItem("LocationID", id);
        } else {
            setClickedItemId(null);
            localStorage.removeItem("LocationID");
        }
    };
    const [LocationViewDetail, setLocationViewDetail] = useState<LocationData | null>(
        null
    );

    const editMaster = async () => {
        try {
            if (clickedItemId !== null) {
                const response = await getByLocationDetail(parseInt(clickedItemId));
                const data = response.data;
                setLocationViewDetail(data);
            } else {
            }
        } catch (error) {
            console.error("Error fetching Compliance Master details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };

    useEffect(() => {
        editMaster();
    }, [clickedItemId]);

    const doc_url = process.env.REACT_APP_IMAGE_URL

    const handleQrDownload = async (qr_id: string) => {
        try {
            await getCheckpointQrImage(qr_id);
            // setClientDetail(response.data.data_list);
        } catch (error) {
            console.log(error);

        }
    }


    const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

    const editStaffMaster = async (id: any) => {
        try {
            const response = await getByStaffDetail(id);
            const data = response.data;
            setStaffViewDetail(data);
            tog_standard()
        } catch (error) {
            return;
        }
    };


    const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
        any[]
    >([]);

    const fetchStaffDocumentDetails = async (id: any) => {
        try {
            const response = await getStaffDocumentDetail("", id);
            setStaffDocumentDetail(response.data.data_list);
        } catch (error) {
            console.log(error);
        }
    };

    const [modal_standard, setmodal_standard] = useState<boolean>(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleOpenDocument = (path: string) => {
        const fullPath = `${doc_url}/${path}`;
        window.open(fullPath, '_blank');
    };

    const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

    const leaveNames = StaffViewDetail?.staff_leaves?.map((leave: { leave_name: string }) => leave.leave_name) || [];

    const getAbbreviation = (name: string) => {
        return name
            ?.split(' ')
            ?.map(word => word.charAt(0))
            ?.join('')
            ?.toUpperCase()
            ?.slice(0, 2);
    };

    const columns = useMemo(
        () => [
            {
                header: "Location Name",
                accessorKey: "name",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps: any) => {
                    const name = cellProps.row.original.name;
                    const itemId = cellProps.row.original.id;
                    return (
                        <>
                            <span style={{ cursor: 'pointer' }} onClick={() => toggleBottomCanvas(itemId)}> {name ? name : "-"}</span>
                        </>
                    )
                }
            },
            {
                header: "Type",
                accessorKey: "is_billable",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps: any) => {
                    const type = cellProps.row.original.type;
                    return (
                        type === "Internal" ?
                            <Badge bg='warning' className="badge-label"> <i className="mdi mdi-circle-medium"></i> INTERNAL</Badge>
                            : <Badge bg='info' className="badge-label"> <i className="mdi mdi-circle-medium"></i> PAYABLE</Badge>
                    );
                }
            },
            {
                header: "Client Name",
                accessorKey: "client_name",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps: any) => {
                    const client = cellProps.row.original.client_name;

                    return (
                        <>
                            {client ? client : "-"}
                        </>
                    )
                }
            },
            ...(canAccess ? [{
                header: "Subcontractor Name",
                accessorKey: "subcontractor_name",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps: any) => {
                    return cellProps.row.original.subcontractor_name;
                }
            }] : []),
            {
                header: "Total Staff",
                accessorKey: "number_of_employees",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const emp = cellProps.row.original.number_of_employees;

                    return (
                        <>
                            {emp ? emp : "-"}
                        </>
                    )
                }
            },
            {
                header: "Status",
                accessorKey: "status",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: true,

                cell: (cellProps: any) => {
                    return (
                        cellProps.row.original.is_archive === 1 ?
                            <span className="badge bg-body-danger rounded-pill border border-danger text-danger">ARCHIVE</span>
                            : <span className="badge bg-body-success rounded-pill border border-success text-success">ACTIVE</span>
                    );
                },
            },
            {
                header: "Action",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const itemId = cellProps.row.original.id;
                    return (
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            <div className="edit">
                                <i className="ri-eye-line link-info" onClick={() => toggleBottomCanvas(itemId)} style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            </div>
                            {
                                cellProps.row.original.is_archive == 1 ?
                                    <div className="edit">
                                        <i className="ri-restart-line link-success" onClick={() => handleRestoreLocation(itemId)} style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                    </div> :
                                    <div className="edit">
                                        <i onClick={() => handleArchiveLocation(itemId)} className="ri-delete-bin-line link-danger" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                    </div>
                            }
                        </div>
                    );
                },
            },
        ],
        []
    );
    const getInitials = (name: string): string => {
        const names = name.split(" ");
        const firstNameInitial = names[0].charAt(0).toUpperCase();
        const lastNameInitial =
            names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : "";
        return `${firstNameInitial}${lastNameInitial}`;
    };
    return (
        <React.Fragment>
            {data.length > 0 ? (
                <TableContainer
                    isPagination={true}
                    columns={columns || []}
                    data={data}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    divClassName="table-responsive table-card mt-0"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
            ) : (
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">No Location Available</h5>
                    </div>
                </div>
            )}

            <Offcanvas
                className="location-details-offcanvas"
                show={isBottom}
                onHide={toggleBottomCanvas}
                placement="bottom"
                style={{ minHeight: "46vh" }}
            >
                <Offcanvas.Header className="location-view-offcanvas border-bottom">
                    {/* <Button className="avatar-sm float-end">
                        <i className="ri-settings-2-line"></i>
                    </Button> */}

                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                        {LocationViewDetail?.name ? `${LocationViewDetail?.name} Details ` : "-"}
                    </Offcanvas.Title>
                    <div>
                        {canAccess &&
                            <Button href="/add-location" className="edit-btn">
                                Edit
                            </Button>
                        }

                        <Button
                            className="close-btn"
                            onClick={() => {
                                setIsBottom(false);
                            }}
                        >
                            <i className="ri-close-line"></i>
                        </Button>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row className="flex-wrap">
                        <Col lg="4">
                            <Card className="details-section">
                                <Card.Body>
                                    <SimpleBar
                                        className="py-0"
                                        style={{ height: "500px", overflowX: "hidden" }}
                                    >
                                        <Row>
                                            <Col lg={12} className="mt-4">
                                                <p className="detail-title">Location Name</p>
                                                <p className="detail-value">{LocationViewDetail?.name ? LocationViewDetail?.name : "-"}</p>
                                            </Col>
                                            <Col lg={12} className="mt-4">
                                                <p className="detail-title">Address</p>
                                                <p className="detail-value">{LocationViewDetail?.address ? LocationViewDetail?.address : "-"}</p>
                                            </Col>
                                            <Col lg={12} className="mt-4">
                                                <p className="detail-title">Subcontractor Name</p>
                                                <p className="detail-value">{LocationViewDetail?.subcontractor_name ? LocationViewDetail?.subcontractor_name : "-"}</p>
                                            </Col>
                                            <Col lg={12} className="mt-4">
                                                <p className="detail-title">Client Name</p>
                                                <p className="detail-value">{LocationViewDetail?.client_name ? LocationViewDetail?.client_name : "-"}</p>
                                            </Col>
                                            <Col lg={12} className="mt-4">
                                                <p className="detail-title">Working Hours</p>
                                                <p className="detail-value">{LocationViewDetail?.start_time ? LocationViewDetail?.start_time : "-"} - {LocationViewDetail?.end_time ? LocationViewDetail?.end_time : "-"}</p>
                                            </Col>
                                            <Col lg={12} className="mt-4">
                                                <p className="detail-title">Working days</p>
                                                <p className="detail-value">
                                                    {LocationViewDetail?.working_days && LocationViewDetail?.working_days.length > 0 ?
                                                        LocationViewDetail.working_days.map((item, index) => (
                                                            <React.Fragment key={index}>
                                                                {item}
                                                                {index < LocationViewDetail.working_days.length - 1 && ', '}
                                                            </React.Fragment>
                                                        ))
                                                        : "-"}
                                                </p>
                                            </Col>

                                            {/* <Col lg={6} className="mt-4">
                                                <p className="detail-title">Billing Rates</p>
                                                <p className="detail-value">-</p>
                                            </Col> */}
                                            {/* <Col lg={12} className="mt-4">
                                                <p className="detail-title">Time Zone</p>
                                                <p className="detail-value">3:30 pm (AEST | GMT +10)</p>
                                            </Col> */}
                                        </Row>
                                    </SimpleBar>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="8" className="locationmap">
                            {/* {(LocationViewDetail?.latitude && LocationViewDetail?.longitude) && */}
                            <LocationMap
                                address={LocationViewDetail?.address ? LocationViewDetail?.address : "No Address Available"}
                                latitude={LocationViewDetail?.latitude ? LocationViewDetail?.latitude : -34.9284989}
                                longitude={LocationViewDetail?.longitude ? LocationViewDetail?.longitude : 138.6007456}
                                name={LocationViewDetail?.name ? LocationViewDetail?.name : "-"}
                            />
                            {/* } */}
                        </Col>
                        <Col lg="4">
                            <Card className="details-section-third">
                                <Card.Body>
                                    <Row>
                                        <Col lg="12">
                                            <h5>
                                                <i className="ri-file-text-line"></i>Location Documents
                                            </h5>
                                        </Col>
                                        <Col lg="12">
                                            <SimpleBar className="py-0" style={{ height: "290px" }}>
                                                {(LocationViewDetail?.documents && LocationViewDetail?.documents?.length > 0) ? (
                                                    <Table responsive className="table table-nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Document Name</th>
                                                                <th scope="col">Visible to Staff</th>
                                                                <th scope="col">Requires Signature</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {LocationViewDetail?.documents?.map((doc) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{doc?.document_name}</td>
                                                                        <td>{doc?.is_viewable_staff === 1 ? "Yes" : "No"}</td>
                                                                        <td>{doc?.require_sign === 1 ? "Yes" : "No"}</td>
                                                                        <td>
                                                                            <i onClick={() => handleOpenDocument(doc.document_path)} className="ri-eye-line link-info"></i>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                ) : (
                                                    <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                                                        <div className="flex-grow-1 d-flex justify-content-center">
                                                            <h6 className="fs-md mb-1">No Documents Available</h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </SimpleBar>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4">
                            <Card className="details-section-third">
                                <Card.Body>
                                    <Row>
                                        <Col lg="12">
                                            <h5>
                                                <i className="ri-map-pin-user-line"></i>Preferred Staff
                                            </h5>
                                        </Col>
                                        <Col lg="12">
                                            <SimpleBar className="py-0" style={{ height: "290px" }}>
                                                {(LocationViewDetail?.staff && LocationViewDetail?.staff?.length > 0) ? (
                                                    <div className="table-responsive mt-0">
                                                        <Table className="m-0">
                                                            <tbody>
                                                                {LocationViewDetail?.staff?.map((staff) => {
                                                                    return (
                                                                        <tr key={staff.staff_id}>
                                                                            <td>
                                                                                <div className="d-flex align-items-center gap-3">
                                                                                    <div className="avatar-sm">
                                                                                        <div className="avatar-title rounded bg-primary-subtle text-primary">
                                                                                            {getInitials(staff.staff_name)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1">
                                                                                        <h6 className="fs-md mb-1" style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                                                                            {staff.staff_name} {staff.sub_abb} <i onClick={() => {
                                                                                                if (staff.staff_id) {
                                                                                                    editStaffMaster(staff.staff_id);
                                                                                                    fetchStaffDocumentDetails(staff.staff_id);
                                                                                                }
                                                                                            }} className="ri-information-line link-info" style={{ cursor: 'pointer', fontSize: '18px' }} ></i>
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                                                        <div className="flex-grow-1 d-flex justify-content-center">
                                                            <h6 className="fs-md mb-1">No Staff Available</h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </SimpleBar>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4">
                            <Card className="details-section-third">
                                <Card.Body>
                                    <Row>
                                        <Col lg="12">
                                            <h5>
                                                <i className="ri-numbers-line"></i>Incident Report
                                            </h5>
                                        </Col>
                                        <Col lg="12">
                                            <SimpleBar className="py-0" style={{ height: "290px" }}>
                                                {(LocationViewDetail?.incident_report_template && LocationViewDetail?.incident_report_template?.length > 0) ? (
                                                    <div className="table-responsive mt-0">
                                                        <Table className="m-0">
                                                            <tbody>
                                                                {LocationViewDetail?.incident_report_template?.map((incident) => {
                                                                    return (
                                                                        <tr key={incident.incident_report_template_id}>
                                                                            <td>
                                                                                <div className="d-flex align-items-center gap-1">
                                                                                    <div className="flex-grow-1">
                                                                                        <h6 className="fs-md mb-1">
                                                                                            {/* <Link
                                                                                            to={"/#"}
                                                                                            className="text-reset"
                                                                                        > */}
                                                                                            {incident.name}
                                                                                            {/* </Link> */}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                                                        <div className="flex-grow-1 d-flex justify-content-center">
                                                            <h6 className="fs-md mb-1">No Incident Report Available</h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </SimpleBar>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4">
                            <Card className="details-section-third">
                                <Card.Body>
                                    <Row>
                                        <Col lg="12">
                                            <h5>
                                                <i className="ri-contacts-book-2-line"></i>Contact Details
                                            </h5>
                                        </Col>
                                        <Col lg="12">
                                            <SimpleBar className="py-0" style={{ height: "290px" }}>
                                                {(LocationViewDetail?.contacts && LocationViewDetail?.contacts?.length > 0) ? (
                                                    <div className="table-responsive mt-0">
                                                        <Table className="m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Contact Name</th>
                                                                    <th scope="col">Contact Number</th>
                                                                    <th scope="col">Contact Email</th>
                                                                    {/* <th scope="col">Contact Type</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {LocationViewDetail?.contacts?.map((contact) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{contact?.contact_person}</td>
                                                                            <td>{contact?.contact_phone}</td>
                                                                            <td>{contact?.contact_email}</td>
                                                                            {/* <td>{contact?.contact_type}</td> */}
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                                                        <div className="flex-grow-1 d-flex justify-content-center">
                                                            <h6 className="fs-md mb-1">No Contact Available</h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </SimpleBar>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4">
                            <Card className="details-section-third">
                                <Card.Body>
                                    <Row>
                                        <Col lg="12">
                                            <h5>
                                                <i className=" ri-map-pin-line"></i>Checkpoints Details
                                            </h5>
                                        </Col>
                                        <Col lg="12">
                                            <SimpleBar className="py-0" style={{ height: "290px" }}>
                                                {(LocationViewDetail?.checkpoints && LocationViewDetail?.checkpoints?.length > 0) ? (
                                                    <div className="table-responsive mt-0">
                                                        <Table className="m-0">
                                                            <tbody>
                                                                {LocationViewDetail?.checkpoints?.map((checkpoint, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className="d-flex align-items-center gap-1">
                                                                                    <div className="flex-grow-1">
                                                                                        <h6 className="fs-md mb-1">
                                                                                            {/* <Link
                                                                                            to={"/#"}
                                                                                            className="text-reset"
                                                                                        > */}
                                                                                            {checkpoint.name}
                                                                                            {/* </Link> */}
                                                                                        </h6>
                                                                                    </div>
                                                                                    <i onClick={() => handleQrDownload(checkpoint.qr_id.toString())} style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-download-2-fill link-info"></i>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                                                        <div className="flex-grow-1 d-flex justify-content-center">
                                                            <h6 className="fs-md mb-1">No Checkpoints Available</h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </SimpleBar>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4" className="location-details-col">
                            <Card className="details-section-second">
                                <Card.Body>
                                    <h5>
                                        <i className="ri-book-line"></i>Location notes
                                    </h5>
                                    <Row>
                                        <Col lg={12}>
                                            <p className="detail-title">Note</p>
                                            <p className="detail-value">
                                                {LocationViewDetail?.notes ? LocationViewDetail?.notes : "-"}
                                            </p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
            <Modal id="myModal" size="sm" show={modal_standard} onHide={() => { tog_standard(); }} >
                {/* <Modal.Header className="modal-title fs-xl" id="myModalLabel">
                                {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                            </Modal.Header> */}
                <Modal.Body>
                    <div className="d-flex align-items-center" >
                        <div className="flex-shrink-0 position-relative">
                            {StaffViewDetail?.avatar ?
                                <img src={imageSrc} alt="" className="avatar-sm rounded" />
                                :
                                <div className="avatar-sm">
                                    <div className="avatar-title rounded bg-primary-subtle text-primary">
                                        {getAbbreviation(StaffViewDetail?.employee_name ? StaffViewDetail?.employee_name : "-")}
                                    </div>
                                </div>

                            }
                        </div>
                        <div className="flex-grow-1 ms-2">
                            <h5 className="fs-md mb-0"><Link to="/pages-profile" className="text-dark">{StaffViewDetail?.first_name} {StaffViewDetail?.last_name}</Link></h5>
                            <p className="text-muted mb-0"> {StaffViewDetail?.access_level ? StaffViewDetail?.access_level : "-"} </p>
                            <p className="text-muted mb-0"> {StaffViewDetail?.employee_status ? StaffViewDetail?.employee_status : "-"} </p>
                        </div>
                    </div>
                    <div style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '18px', cursor: 'pointer' }}>
                        <i onClick={tog_standard} className="ri-close-line"></i>
                    </div>
                    <div className="mt-4">
                        <p className="text-muted mb-2">
                            <i className="bi bi-telephone align-baseline me-1"></i>
                            {StaffViewDetail?.mobile_no ? (
                                <Link to={`tel:${StaffViewDetail.mobile_no}`} className="text-muted" style={{ paddingLeft: '4px' }}>
                                    {StaffViewDetail.mobile_no}
                                </Link>
                            ) : (
                                "-"
                            )}
                        </p>
                        <p className="text-muted mb-2">
                            <i className="bi bi-envelope align-baseline me-1"></i>
                            {StaffViewDetail?.email ? (
                                <Link to={`mailto:${StaffViewDetail.email}`} className="text-muted" style={{ paddingLeft: '4px' }}>{StaffViewDetail.email} </Link>
                            ) : (
                                "-"
                            )}
                        </p>
                        <p className="text-muted mb-2"><i className="bi bi-geo-alt align-baseline me-1"></i> {StaffViewDetail?.address ? StaffViewDetail?.address : "-"} </p>
                        <p className="text-muted mb-2"><i className="mdi mdi-gender-transgender align-baseline me-1"></i> {StaffViewDetail?.gender ? StaffViewDetail?.gender : "-"} </p>
                        <p className="text-muted mb-0"><i className="ri-cake-line align-baseline me-1"></i> {StaffViewDetail?.dob ? StaffViewDetail?.dob : "-"} </p>
                    </div>
                    {StaffDocumentDetail.filter((doc) => doc.document_type === "Licenses").length > 0 &&
                        <div className="border-bottom border-top border-dashed py-4 mt-4">
                            <h5 className="card-title mb-3">License</h5>
                            <div className="vstack gap-3">
                                {StaffDocumentDetail
                                    .filter((doc) => doc.document_type === "Licenses")
                                    .map((doc, index) => (
                                        <div key={index} className="d-flex gap-2 align-items-center position-relative">
                                            <div className="flex-shrink-0">
                                                <div className="avatar-sm border border rounded">
                                                    <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                                                        <i className="bi bi-file-pdf"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6>License No : {doc.ref_no}</h6>
                                                <p className="text-muted mb-0">Expiry Date : {doc.expiry_date ? doc.expiry_date : "-"}</p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <Link to={`#`} onClick={() => handleOpenDocument(doc.document_path)} className="icon-link text-reset stretched-link fs-xl" style={{ transform: "translate3d(0, -.125rem, 0)" }}>
                                                    <i className="ri-eye-line link-info"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default LocationTable;
