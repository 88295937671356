import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert, Button, Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import { format } from 'date-fns';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { getByUserProfileDetail, updateUserProfileDetail } from "helpers/saibackend_helper";
//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "Common/withRouter";
import { toast } from 'react-toastify';
import SearchableAddress from "helpers/SearchableAddress";
import 'react-toastify/dist/ReactToastify.css';
import Flatpickr from "react-flatpickr"
//Import Breadcrumb
import Breadcrumb from 'Common/BreadCrumb';

interface AddressData {
    address: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
}

const UserProfile = () => {

    document.title = "User Profile | The Guard Duty";

    // const dispatch = useDispatch<any>();

    // const [email, setemail] = useState("admin@gmail.com");
    // const [name, setname] = useState("Admin");
    // const [idx, setidx] = useState(1);

    // const selectProfile = createSelector(
    //     (state: any) => state.Profile,
    //     (profile) => ({
    //         user: profile.user,
    //         success: profile.success,
    //         error: profile.error,
    //     })
    // );

    // const { user, success, error } = useSelector(selectProfile);

    // useEffect(() => {
    //     if (localStorage.getItem("authUser")) {
    //         const obj = JSON.parse(localStorage.getItem("authUser") || "{}");

    //         if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //             obj.displayName = user.username;
    //             setname(obj.displayName || "Admin");
    //             setemail(obj.email);
    //             setidx(obj.uid);
    //         } else if (
    //             process.env.REACT_APP_DEFAULTAUTH === "fake" ||
    //             process.env.REACT_APP_DEFAULTAUTH === "jwt"
    //         ) {

    //             if (!isEmpty(user)) {
    //                 obj.data.displayName = user.username;
    //                 localStorage.removeItem("authUser");
    //                 localStorage.setItem("authUser", JSON.stringify(obj));
    //             }
    //             // setname(obj.data.displayName ? obj.data.displayName : "Admin");
    //             setname(user?.username || "Admin")
    //             setemail(obj.email || obj.data.email);
    //             setidx(obj.uid || obj.data._id);
    //         }


    //         setTimeout(() => {
    //             dispatch(resetProfileFlag());
    //         }, 3000);
    //     }
    // }, [dispatch, user]);

    const obj = JSON.parse(localStorage.getItem("authUser") || "{}");
    const userId = obj.data.user_detail.id

    const [address, setAddress] = useState<string>('');

    const handleAddressChange = (data: AddressData | boolean) => {
        if (typeof data === 'boolean') return;
        setAddress(data.address);
        formik.setFieldValue("address", data.address);
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("First name is required").matches(/^[A-Za-z0-9]+$/, "First name can only contain letters and digits"),
        last_name: Yup.string().required("Last name is required").matches(/^[A-Za-z0-9 ]+$/, "Last name can only contain letters, digits, and spaces"),
        contact_no: Yup.string()
            .required("Contact Number is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, "Must be at least 10 digits"),
        dob: Yup.date().required("Date of Birth is required").nullable(),
        gender: Yup.string().required("Gender is required"),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        // address: Yup.string().required("Address is required"),
    });


    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            contact_no: "",
            dob: "",
            gender: "",
            email: "",
            address: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const formattedDob = format(new Date(values.dob), 'yyyy-MM-dd');
                const updatedValues = { ...values, dob: formattedDob };
                console.log(values);
                const response = await updateUserProfileDetail(userId, updatedValues.first_name, updatedValues.last_name, updatedValues.contact_no, updatedValues.dob, updatedValues.gender, updatedValues.email, updatedValues.address);
                if (response.status === 1) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            } catch (error) {
                console.error("Error Adding/Updating User Profile:", error);
                toast.error("Error User Profile. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const editProfile = async () => {
        try {
            const response = await getByUserProfileDetail(userId);
            const data = response.data;
            console.log(data);

            formik.setValues({
                first_name: data.first_name,
                last_name: data.last_name,
                contact_no: data.contact_no,
                dob: data.dob,
                gender: data.gender,
                email: data.email,
                address: data.address
            });
        } catch (error) {
            console.error("Error fetching Compliance Master details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };

    useEffect(() => {
        editProfile()
    }, [])

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = event.target.value;
        if (selectedId) {
            formik.setFieldValue('gender', selectedId);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="User Profile" pageTitle="Dashboard" />

                    {/* <Row>
                        <Col lg="12">
                            {error && error ? <Alert variant="danger">{error}</Alert> : null}
                            {success ? <Alert variant="success">Username Updated To {name}</Alert> : null}

                            <Card>
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mx-3">
                                            <img
                                                src={avatar}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                            />
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="text-muted">
                                                <h5>{name}</h5>
                                                <p className="mb-1">{email}</p>
                                                <p className="mb-0">Id no: #{idx}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}

                    {/* <h4 className="card-title mb-4">Change User Name</h4> */}

                    <Card>
                        <Card.Body>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>First Name</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    name="first_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter First Name"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.first_name}
                                                    isInvalid={!!(formik.touched.first_name && formik.errors.first_name)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.first_name}
                                                </Form.Control.Feedback>
                                                <i style={{ fontSize: "16px" }} className="bx bx-face"></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Last Name</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    name="last_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Last Name"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.last_name}
                                                    isInvalid={!!(formik.touched.last_name && formik.errors.last_name)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.last_name}
                                                </Form.Control.Feedback>
                                                <i style={{ fontSize: "16px" }} className="bx bx-face"></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3 d-flex flex-column form-icon">
                                            <Form.Label htmlFor="JoiningdatInput">Date of Birth</Form.Label>
                                            <Flatpickr
                                                className="form-control-lg form-control-icon"
                                                name="dob"
                                                value={formik.values.dob}
                                                onChange={(dob: any[]) => formik.setFieldValue('dob', dob[0])}
                                                style={{ border: '1px solid #dde1ef' }}
                                                placeholder="Select Date of Birth"
                                                options={{
                                                    maxDate: 'today'
                                                }}
                                                isInvalid={!!(formik.touched.dob && formik.errors.dob)}
                                            />
                                            {formik.touched.dob && formik.errors.dob && (
                                                <div className="text-danger">{formik.errors.dob}</div>
                                            )}
                                            <i style={{ fontSize: '16px' }} className="ri-calendar-event-line mt-4"></i>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="mb-3 form-icon form-select-icon">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Select
                                                className="form-select-lg form-control-icon"
                                                id="floatingSelect"
                                                name="gender"
                                                value={formik.values.gender}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!(formik.touched.gender && formik.errors.gender)}
                                            >
                                                <option defaultValue="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.gender}
                                            </Form.Control.Feedback>
                                            <i className="bx bx-face"></i>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    name="email"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Email"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                    isInvalid={!!(formik.touched.email && formik.errors.email)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.email}
                                                </Form.Control.Feedback>
                                                <i style={{ fontSize: "16px" }} className="ri-mail-line"></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    name="contact_no"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Number"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.contact_no}
                                                    isInvalid={!!(formik.touched.contact_no && formik.errors.contact_no)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.contact_no}
                                                </Form.Control.Feedback>
                                                <i style={{ fontSize: "16px" }} className="ri-keyboard-line"></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col lg="3">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Address</Form.Label>
                                            <SearchableAddress
                                                label="Location Address"
                                                name="address"
                                                placeholder="Enter Address"
                                                onChange={handleAddressChange}
                                                invalid={false}
                                                defaultValue={{
                                                    latitude: "",
                                                    longitude: "",
                                                    address: formik.values.address,
                                                }}
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <div className="text-danger">{formik.errors.address}</div>
                                            )}
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                <div className="text-center mt-4">
                                    <Button type="submit" variant="danger">
                                        Update Profile
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(UserProfile);
